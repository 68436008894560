import { Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

import AuthorizedRoute from '@/components/AuthorizedRoute';
import ExpiryChecker from '@/components/ExpiryChecker';
import FallbackSpin from '@/components/Shared/FallbackSpin';
import CompanyFlagRoute from '@/components/CompanyFlagRoute';
import LimitsChecker from '@/components/LimitsChecker';

import AddNewCompany from '../views/AddNewCompany';
import Dashboard from '../views/Dashboard';
import InvestmentOverview from '../views/InvestmentOverview';

const EditCompany = lazy(() => import('../views/EditCompany'));
const TransactionRequests = lazy(() => import('../views/TransactionRequests'));
const MyActivity = lazy(() => import('../views/MyActivity'));
const Contracts = lazy(() => import('../views/Contracts'));

export const CompaniesRoutes = () => {
  return (
    <Suspense fallback={<FallbackSpin />}>
      <Routes>
        <Route element={<AuthorizedRoute allowedPermissions={['company.dashboard']} />}>
          <Route element={<ExpiryChecker />}>
            <Route path="dashboard" element={<Dashboard />} />
          </Route>
        </Route>
        <Route element={<AuthorizedRoute allowedPermissions={['company.my-investment']} />}>
          <Route element={<ExpiryChecker />}>
            <Route path="investment-overview">
              <Route element={<LimitsChecker feature="shares" limitKeys={['limits.shares.sell']} showHud={false} />}>
                <Route path="my-investments" element={<InvestmentOverview />} />
              </Route>
              <Route
                element={
                  <AuthorizedRoute
                    allowedPermissions={['company.my-investment']}
                    allowedFeatures={['limits.shares.sell']}
                    feature="shareSellAndTransfer"
                  />
                }
              >
                <Route element={<LimitsChecker feature="shares" limitKeys={['limits.shares.sell']} showHud={false} />}>
                  <Route
                    path="transaction-requests"
                    element={<CompanyFlagRoute allowedFlag="is_admin" element={<TransactionRequests />} />}
                  />
                </Route>
                <Route element={<AuthorizedRoute allowedPermissions={['company.contracts.list']} />}>
                  <Route
                    element={<LimitsChecker feature="shares" limitKeys={['limits.shares.sell']} showHud={false} />}
                  >
                    <Route path="contracts" element={<Contracts />} />
                  </Route>
                </Route>
                <Route element={<LimitsChecker feature="shares" limitKeys={['limits.shares.sell']} showHud={false} />}>
                  <Route path="my-activity" element={<MyActivity />} />
                </Route>
              </Route>
            </Route>
          </Route>
        </Route>
        <Route path="new" element={<AddNewCompany />} />
        <Route path=":id/edit" element={<EditCompany />} />
      </Routes>
    </Suspense>
  );
};
