import { useSelectedCompanyStore } from '@/stores/selectedCompany';
import { Navigate } from 'react-router-dom';

const IsAdminMiddleware = ({ children }: { children: JSX.Element }) => {
  const { selectedCompany } = useSelectedCompanyStore((state) => state);

  if (selectedCompany?.is_owner) {
    return children;
  } else {
    return <Navigate to="/" replace />;
  }
};

export default IsAdminMiddleware;
