import SARIcon from '@/components/Icons/SARIcon';
import { formatNumber } from './formatNumber';
import { Flex } from 'antd';

export const formatCurrencyNumber = (
  number: string | number,
  currency: string,
  options: Intl.NumberFormatOptions = { minimumFractionDigits: 0, maximumFractionDigits: 5 },
) => {
  const formated = formatNumber(number, options);

  return (
    <Flex
      align="center"
      gap={4}
      style={{
        display: 'inline-flex',
        fontSize: 'inherit',
      }}
    >
      {currency === 'SAR' ? <SARIcon /> : `${currency} `}
      {formated}
    </Flex>
  );
};
