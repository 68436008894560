import { TFunction } from 'i18next';
import { ColumnsType } from 'antd/lib/table/InternalTable';

import withLoadingSkeleton from '@/HOCs/withLoadingSkeleton';
import { ConvertibleInstrumentsData } from '@/features/captable/types';
import { formatNumber } from '@/utils/formatNumber';
import { formatCurrencyNumber } from '@/utils/formatCurrencyNumber';

import type { Instrument } from '../types';

export const convertibleInstrumentsColumns = (
  isLoading: boolean,
  currency: string,
  t: TFunction<'captable', 'overview'>,
): ColumnsType<ConvertibleInstrumentsData | Instrument | Record<string, number>> => [
  {
    title: t('instrumentHolder'),
    dataIndex: 'shareholder',
    render: withLoadingSkeleton(isLoading, (val: string) => val),
  },
  {
    title: t('name'),
    dataIndex: 'name',
    render: withLoadingSkeleton(isLoading, (val: string) => val),
  },
  {
    title: t('principal'),
    dataIndex: 'principal_amount',
    render: withLoadingSkeleton(isLoading, (val: number) => formatCurrencyNumber(val, currency)),
  },
  {
    title: t('interest'),
    dataIndex: 'interest_rate',
    render: withLoadingSkeleton(isLoading, (val: number) => (
      <>{`${formatNumber(val, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}%`}</>
    )),
  },
  {
    title: t('valuationCap'),
    dataIndex: 'valuation_cap',
    render: withLoadingSkeleton(isLoading, (val: number) => formatCurrencyNumber(val, currency)),
  },
  {
    title: t('discountPercentage'),
    dataIndex: 'discount',
    render: withLoadingSkeleton(isLoading, (val: number) => (
      <>{`${formatNumber(val, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}%`}</>
    )),
  },
];
