import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import { App } from 'antd';
import { axiosClient } from '@/lib/axios';
import { AxiosError } from 'axios';

type PaymentPayload = {
  companyID: number;
  body: {
    number_of_shares: number;
    shareholder_id: number;
    issue_share_id: number;
    code?: string;
  };
};

type PaymentResponse = {
  redirect_url: string;
};

export const transferSharesPayment = async ({ companyID, body }: PaymentPayload): Promise<PaymentResponse> => {
  const response = await axiosClient.post<PaymentResponse>(
    `/companies/${companyID}/cap-table/equities/issued-shares/transfer/payment`,
    body,
  );

  return response.data;
};

type UseTransferSharesPaymentOptions = {
  config?: Partial<UseMutationOptions<PaymentResponse, AxiosError<{ message: string }>, PaymentPayload>>;
  postSuccess?: (redirectUrl: string) => void;
};

export const useTransferSharesPayment = ({ config, postSuccess }: UseTransferSharesPaymentOptions) => {
  const { notification: toaster } = App.useApp();
  return useMutation({
    onError: (error) => {
      toaster.error({
        message: 'Something Went Wrong',
        description: error.message,
      });
    },
    onSuccess: async (data) => {
      postSuccess?.(data.redirect_url);
    },
    ...config,
    mutationFn: transferSharesPayment,
  });
};
