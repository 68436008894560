import { Route, Routes } from 'react-router-dom';
import { Suspense, lazy } from 'react';
import FallbackSpin from '@/components/Shared/FallbackSpin';
import UserPlan from '../views/UserPlan';
import IsAdminMiddleware from '@/utils/IsAdminMiddleware';

const Profile = lazy(() => import('../views/Profile'));

export const UserRoutes = () => {
  return (
    <Suspense fallback={<FallbackSpin />}>
      <Routes>
        <Route path="profile" element={<Profile />} />
        <Route
          path="user-plan"
          element={
            <IsAdminMiddleware>
              <UserPlan />
            </IsAdminMiddleware>
          }
        />
      </Routes>
    </Suspense>
  );
};
