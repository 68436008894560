import { StarIcon } from '@/assets/Icon/star';
import { Flex } from 'antd';
import themeToken from '@lib/theme/tokens/index';

const CompanyLogoPremiumIcon = () => {
  return (
    <Flex
      style={{
        position: 'absolute',
        top: 0,
        right: 0,
        background: themeToken['branding-secondary-1'],
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '16px',
        height: '16px',
        borderRadius: '100%',
      }}
    >
      <Flex
        style={{
          background: themeToken['branding-secondary-1'],
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '12px',
          height: '12px',
          mixBlendMode: 'multiply',
          borderRadius: '100%',
        }}
      >
        <StarIcon width={8} height={8} rotation={-15} fillColor={themeToken['branding-secondary-6']} />
      </Flex>
    </Flex>
  );
};

export default CompanyLogoPremiumIcon;
