import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { App } from 'antd';

import { queryClient } from '@/lib/react-query';
import axiosClient from '@/lib/axios';

type ApplyPromoParams = {
  companyID: number;
  body: { code: string };
};

export const applyPromo = async ({ companyID, body }: ApplyPromoParams) => {
  const response = await axiosClient.put<{
    price: number;
  }>(`/companies/${companyID}/cap-table/equities/issued-shares/trade/promo-code/apply`, body);

  return response.data;
};

type UseApplyPromoOptions = {
  config?: Partial<
    UseMutationOptions<
      {
        price: number;
      },
      AxiosError<{ message: string }>,
      ApplyPromoParams
    >
  >;
  postSuccess?: () => void;
};

export const useApplyPromo = ({ postSuccess, config }: UseApplyPromoOptions) => {
  const { notification: toaster } = App.useApp();

  return useMutation({
    onError: (error) => {
      toaster.error({
        message: 'Something Went Wrong',
        description: error.response?.data.message,
      });
    },
    onSuccess: async (_, { companyID }) => {
      await queryClient.invalidateQueries({
        queryKey: ['payment-price', companyID],
      });

      toaster.success({
        message: 'Updated Successfully',
        description: 'Your promo is applied successfully',
      });
      postSuccess?.();
    },
    ...config,
    mutationFn: applyPromo,
  });
};
