import type { ColumnsType } from 'antd/es/table';
import { TFunction } from 'i18next';
import dayjs from 'dayjs';

import withLoadingSkeleton from '@/HOCs/withLoadingSkeleton';
import { PaymentHistory, Plan } from '@/features/premium/types';
import { Flex } from 'antd';
import SARIcon from '@/components/Icons/SARIcon';

export const paymentHistoryColumns = ({
  isLoading,
  t,
}: {
  isLoading: boolean;
  t: TFunction<'plans'>;
}): ColumnsType<PaymentHistory> => [
  {
    title: t('plan'),
    dataIndex: 'plan',
    sorter: true,
    render: withLoadingSkeleton(isLoading, (plan: Plan) => plan.name),
  },
  {
    title: t('payedAt'),
    dataIndex: 'created_at',
    sorter: true,
    render: withLoadingSkeleton(isLoading, (created_at: string) => dayjs(created_at).format('MMMM D, YYYY, hh:mm A')),
  },
  {
    title: t('price'),
    width: '20%',
    dataIndex: 'price',
    render: withLoadingSkeleton(isLoading, (price: string): JSX.Element => {
      return (
        <Flex gap={4} align="center">
          <SARIcon />
          {price}
        </Flex>
      );
    }),
  },
];
