import { useEffect, useMemo } from 'react';
import { Outlet, useLocation, Navigate } from 'react-router-dom';

import { useGetCompany } from '@/features/company/api/getCompany';
import { usePermissionsStore } from '@/stores/Permissions';
import { useSelectedCompanyStore } from '@/stores/selectedCompany';
import type { Permissions } from '@/types/permissions';

type Props = {
  allowedPermissions: Permissions[];
  allowedFeatures?: string[];
  feature?: string;
};

const AuthorizedRoute = ({ allowedPermissions, allowedFeatures, feature }: Props) => {
  const { selectedCompany, companiesList } = useSelectedCompanyStore((state) => state);
  const { data: companyData } = useGetCompany({
    companyID: selectedCompany?.cid as number,
  });
  const { setPermissions, permissions } = usePermissionsStore((state) => state);
  const premiumFeatures = useMemo(() => {
    const features =
      companyData?.current_plan?.features
        ?.filter(({ premium }) => premium)
        .map(({ key, included, premium }) => ({ key, included, premium })) || [];
    return features;
  }, [companyData]);

  const shouldPassPlanChecks = () => {
    let shouldPass = false;
    allowedPermissions.forEach((permission) => {
      const premiumFeature = premiumFeatures.find((feat) => feat.key === permission);
      if (!premiumFeature) shouldPass = true;
      else if (premiumFeature && !premiumFeature.included) shouldPass = false;
      else shouldPass = true;
    });

    allowedFeatures?.forEach((el) => {
      const premiumFeature = premiumFeatures.find((feat) => feat.key === el);
      if (premiumFeature && !premiumFeature.included) shouldPass = false;
      else shouldPass = true;
    });
    return shouldPass;
  };

  const location = useLocation();

  useEffect(() => {
    if (companyData) {
      setPermissions(companyData.user_permissions);
    }
  }, [companyData, setPermissions]);

  if (!companiesList.length) return <Navigate to="/company/new" replace />;
  if (!permissions.length) return <Outlet />;
  if (allowedPermissions.every((permission) => permissions.includes(permission))) {
    if (shouldPassPlanChecks()) return <Outlet />;
    else return <Navigate to={`/go-premium/upgrade?feature=${feature}`} state={{ from: location }} replace />;
  } else return <Navigate to="/go-premium/no-access" state={{ from: location }} replace />;
};

export default AuthorizedRoute;
