import { SVGProps } from 'react';
const ShieldIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={48} height={48} fill="none" {...props}>
    <circle cx={24} cy={24} r={24} fill="#F0EAFB" transform="rotate(-90 24 24)" />
    <circle
      cx={24}
      cy={24}
      r={18}
      fill="#F0EAFB"
      style={{
        mixBlendMode: 'multiply',
      }}
    />
    <path fill="#fff" d="M18 17h12v11H18z" />
    <path
      fill="#6430DA"
      d="M15.783 14.826 24 13l8.217 1.826a1 1 0 0 1 .783.976v9.987a6 6 0 0 1-2.672 4.992L24 35l-6.328-4.219A6 6 0 0 1 15 25.79v-9.988a1 1 0 0 1 .783-.976ZM24 25.5l2.939 1.545-.561-3.272 2.377-2.318-3.286-.478L24 18l-1.47 2.977-3.285.478 2.377 2.318-.56 3.272L24 25.5Z"
    />
  </svg>
);
export default ShieldIcon;
