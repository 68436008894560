export default {
  vesting: {
    captable: 'جدول حصص المُلاك',
    vestingSchedules: 'جدول استحقاق الملكية',
    vestingSchedule: 'جدول استحقاق الملكية',
    description: 'ضع جدول استحقاق لمنح موظفيك/مستشاريك حقوق الملكية الكاملة التي يقدمها صاحب العمل بمرور الوقت',
    addVestingSchedules: 'أضف جدول استحقاق',
    editVestingSchedule: 'تعديل جدول استحقاق',
    emptyStateDescription:
      'في الخطوة التالية، ستكون قادرًا على إنشاء خطط ومجموعات لمنح خيارات الأسهم لموظفيك ومستشاريك. ونوصي بإعداد جميع المعلومات المتعلقة بخيارات الأسهم وخطط الموظفين قبل النقر الانتقال للخطوة التالية.',

    vestingTable: {
      name: 'الاسم',
      duration: 'المدة الزمنية',
      cliff: '(أشهر) Cliff منحنى',
      upfrontVesting: 'استحقاق ملكية مسبق',
      actions: 'خيارات',
    },

    form: {
      vestingScheduleName: 'اسم جدول الاستحقاق',
      vestingDuration: 'مدة الاستحقاق (أشهر)',
      vestingFrequency: 'تكرار الاستحقاق (أشهر)',
      upfrontVesting: 'الاستحقاق المسبق',
      vestingFrequencyHint: 'يحدث التكرار في نهاية الشهر',
      upfrontVestingShares: 'الاستحقاق المسبق (نسبة)',
      vestingCliff: 'منحنى الاستحقاق',
      Cliff: 'منحنى الاستحقاق (نسبة)',
      CustomAmountVestedAtCliff: 'تخصيص الأسهم المستحقة عند المنحني',
      vestedAtACliff: 'عدد الأسهم المستحقة عند المنحنى Cliff',
      comment: 'تعليق',
      commentPlaceholder: 'أترك أي تعليق أو ملاحظة',
      submit: 'تأكيد',
      back: 'رجوع',
    },

    details: {
      details: 'تفاصيل جدول الاستحقاق',
      vestingDuration: 'مدة الاستحقاق',
      vestingFrequency: 'تكرار الاستحقاق',
      amountVestedAtCliff: 'المبلغ مستحق في المنحنى',
      Cliff: 'منحنى الاستحقاق',
      upfrontVestingAmount: 'الاستحقاق المسبق',
      comment: 'تعليق',
      close: 'إغلاق',
      months: 'أشهر',
      createdDate: 'تاريخ الانشاء',
    },
  },

  overview: {
    dashboard: 'نظرة عامة',
    personalHoldings: 'استثماراتي',
    commonShares: 'أسهم عادية',
    preferredShares: 'أسهم ممتازة',
    convertibleInstrument: 'السندات القابلة للتحويل',
    authorizedShares: 'الأسهم المصرح بها',
    sharesIssued: 'الأسهم المصدرة',
    availableShares: 'الأسهم المتاحة',
    invitedStakeholders: 'المساهمون',
    invitationsAccepted: 'تم قبول الدعوة (الدعوات)',
    shareholdersInvited: 'تم دعوة مساهم',
    captable: 'جدول حصص الملكية',
    captableOverview: 'نظرة عامة على حصص الملكية',
    descriptionForHowItWorks: 'وصف لكيفية عمله',
    addNewEquity: 'إضافة فئة جديدة',
    class: 'فئة الأسهم',
    shares: 'أسهم',
    ownershipFDPercentage: 'نسبة الملكية (FD) ٪',
    ownershipPercentage: 'نسبة الملكية ٪',
    optionsAvailableToGrant: 'اسهم متاحة للمنح',
    total: 'الإجمالي',
    convertibleInstruments: 'السندات القابلة للتحويل',
    addNewInstruments: 'إضافة سندات جديدة',
    viewMore: 'عرض المزيد',
    principal: 'أصل الدين',
    interest: 'الفائدة',
    valuationCap: 'سقف التقييم',
    discountPercentage: 'الخصم (٪)',
    valuationHistory: 'سجل التقييم',
    latestValuation: 'آخر تقييم',
    totalCapitalRaised: 'إجمالي المبالغ التي تم جمعها',
    quickActions: 'إجراءات سريعة',
    addEquity: 'إضافة الأسهم',
    recommendPreparation: 'نوصيك بتجهيز البيانات التالية والتي ستكون مفيدة خلال الخطوة القادمة',
    ensureRecentDocuments: 'تأكد من توفر نسخة محدثة من عقد تسجيل التاسيس او السجل التجاري ومذكرات الشروط',
    needHelpVisitSupport: 'إذا كنت بحاجة إلى أي مساعدة أو التعرف على كيفية العمل، قم بزيارة صفحة المساعدة والدعم',
    addingNewEquity: 'إضافة أسهم جديدة',
    suggestedNames: 'الأسماء المقترحة',
    selectDate: 'تحديد التاريخ',
    sharesAuthorized: 'الأسهم المصرح بها',
    numberOfSharesAssigned: 'عدد الأسهم المعينة لهذه الفئة',
    preMoneyValuationOptional: 'تقييم ما قبل الاستثمار (إختياري)',
    preMoneyValuation: 'تقييم ما قبل الاستثمار',
    commentOptional: 'التعليق (إختياري)',
    comment: 'تعليق',
    commentPlaceholder: 'أترك أي تعليق أو ملاحظة',
    back: 'الرجوع',
    submit: 'إرسال',
    addOption: 'اضافة خيار الأسهم',
    empowerYourEmployees: 'مكّن موظفيك',
    nextStepOptionPlanInfo:
      'في الخطوة التالية، ستكون قادرًا على إنشاء خطط ومجموعات لمنح خيارات الأسهم لموظفيك ومستشاريك. ونوصي بإعداد جميع المعلومات المتعلقة بخيارات الأسهم وخطط الموظفين قبل النقر الانتقال للخطوة التالية.',
    options: 'خيارات الاسهم',
    option: 'خيار الأسهم',
    equity: 'الأسهم',
    addNewPool: 'إضافة تجميع جديد',
    poolName: 'اسم التجميع',
    assignNameToPool: 'تسمية التجميع',
    addAuthorizedSharesForPlan: 'أضف الأسهم المصرح بها لهذه الخطة',
    addConvertibleInstruments: 'إضافة السندات القابلة للتحويل',
    addNewConvertibleInstrument: 'اضافة السندات الجديدة القابلة للتحويل',
    vestingSchedule: 'جدول الاستحقاق',
    setVestingSchedule:
      'قم بتحديد جدول الاستحقاق لمنح موظفيك حقوق الملكية الكاملة للأصول المقدمة من جهة العمل مع مرور الوقت.',
    addVestingSchedules: 'إضافة جدول الاستحقاق',
    vestingName: 'اسم جدول الاستحقاق',
    duration: 'المدة',
    cliff: 'منحنى',
    upfrontVesting: 'مبلغ الاستحقاق المقدم',
    actions: 'خيارات',
    edit: 'تعديل',
    delete: 'حذف',
    vestingScheduleName: 'اسم جدول الاستحقاق',
    vestingDurationMonths: 'مدة استحقاق الملكية (الشهور)',
    vestingFrequencyMonths: 'تكرار الاستحقاق (أشهر)',
    frequencyEndOfMonth: 'سيحدث تكرار الاستحقاق في نهاية الشهر',
    customAmountVestedAtCliff: 'مبلغ للاستحقاق عند المنحنى',
    name: 'الاسم',
    simulateInvestmentRound: 'محاكاة الجولة الاستثمارية',
    simulateExitScenario: 'محاكاة نمذجة التخارج',
    investmentOverview: 'نظرة عامة على الاستثمار',
    multiple: 'المكرر',
    multiples: 'المضاعفات',
    percentage: 'النسبة ٪',
    upfrontVestingAmount: 'الاستحقاق المسبق',
    close: 'إغلاق',
    prefix: 'الاختصار',
    AddToDataroom: 'إضافة إلى غرفة البيانات',
    addNewShareholder: 'إضافة مساهم جديد',
    manageUsers: 'إدارة المساهمين ',
    ownershipByShareholders: 'الملكية (FD) ٪ حسب المساهمين',
    ownershipByShareClass: 'الملكية (FD) ٪ حسب فئة الأسهم',
    authorizedSharedUsage: '% الأسهم الممنوحة',
    shareholder: 'المساهم',
    shareholders: 'المساهمين',
    needHelpOrSeeHowItWorks: 'إذا كنت بحاجة إلى أي مساعدة أو التعرف على كيفية العمل',
    visitHelpAndSupport: 'قم بزيارة صفحة المساعدة والدعم',
    equitySecurities: 'الأسهم',
    totalIssued: 'الإجمالي الصادر',
    type: 'النوع',
    issueShares: 'إصدار الأسهم',
    equityType: 'نوع الأسهم',
    equities: 'أسهم',
    capitalCommited: 'رأس المال الملزم',
    seniority: 'الأولوية',
    conversionRatio: 'نسبة التحويل',
    className: 'اسم الفئة',
    download: 'تحميل',
    detailedCaptable: 'تفاصيل جدول الرسملة',
    grantName: 'اسم المنحة',
    vested: 'مستحق',
    yes: 'نعم',
    no: 'لا',
    remainingToVest: 'متبقي الاستحقاق',
    exercisePrice: 'سعر التنفيذ',
    totalCost: 'اجمالي التكلفة',
    captableEquities: 'حصص الملكية - الأسهم',
    addEquities: 'أضف الأسهم',
    equitySecuritiesDetail: 'تفاصيل الفئة',
    equityDetails: 'تفاصيل الأسهم',
    approvalDate: 'تاريخ الموافقة',
    equityShareholdersOverview: 'نظرة عامة على حصص المساهمين',
    myEquity: 'أسهمي',
    sharesValue: 'قيمة الأسهم',
    myInvestments: 'استثماراتي',
    currentPricePerShare: 'السعر الحالي للسهم الواحد',
    sharesDetailsTable: 'جدول تفاصيل الأسهم',
    mySharesDetails: 'تفاصيل أسهمي',
    pricePerShare: 'سعر السهم',
    issuanceDate: 'تاريخ الإصدار',
    optionsTable: 'جدول الخيارات',
    myOptionsDetails: ' تفاصيل خياراتي للأسهم',
    frequency: 'التكرار',
    vestingPlansOverTime: 'جدول استحقاق الملكية',
    noChartData: 'لا توجد رسوم بيانية',
    totalOptions: 'إجمالي خيارات الأسهم',
    currentVesting: 'الاستحقاق الحالي',
    rate: 'معدل',
    youAreAboutToAddCompanyFoundersAndUsers: 'أنت على وشك إضافة مؤسسي شركتك و المستخدمين',
    makeSureToAddAllCompanyUsers:
      'تأكد من إضافة جميع المتسخدمين ، مثل المؤسسين/المستثمرين/ الموظفين، وما إلى ذلك لإصدار أسهم لهم',
    addUsersAndFounders: 'إضافة المستخدمين والمؤسسين',
    shareName: 'اسم السهم',
    sharePrice: 'سعر السهم',
    numberOfShares: 'عدد الأسهم',
    totalValuation: 'اجمالي التقييم',
    equityClass: 'فئة الأسهم',
    participationCap: 'سقف المشاركة',
    dateOfIssuance: 'تاريخ الإصدار',
    sharesAvailableToIssue: 'الأسهم المتاحة لإصدارها في هذه الفئة من الأسهم',
    addNewVestingSchedule: 'أضف جدول استحقاق جديد',
    vestingStartDate: 'تاريخ بداية الاستحقاق',
    numberOfSharesIssued: 'عدد الأسهم المصدرة',
    grantID: 'معرف المنحة',
    issueShare: 'إصدار الأسهم',
    issueSharesDescription: 'إصدار الأسهم لأي مساهم في شركتك',
    share: 'سهم',
    instrumentHolder: 'حامل السند',
    common: 'عادية',
    preferred: 'ممتازة',
    all: 'جميعها',
    vestingDetails: 'تفاصيل الاستحقاق',
    price: 'السعر',
    totalVested: 'كامل الاستحقاق',
    sell: 'بيع',
    transfer: 'تحويل',
    sellShares: 'بيع الأسهم',
    numberOfSharesToSellLabel: 'عدد الأسهم المراد بيعها',
    numberOfSharesToSellPlacholder: 'أدخل عدد الأسهم المراد بيعها',
    transferShares: 'تحويل الأسهم',
    theReceiverUser: 'المستخدم المستلم',
    amountOfShares: 'عدد الأسهم',
    reason: 'السبب',
    reasonOfReject: 'سبب الرفض',
    reasonPlaceholderField: 'أدخل سبب الرفض',
    shareDetails: 'تفاصيل السهم',
    viewDetails: 'عرض التفاصيل',
    owner: 'المالك',
    status: 'الحالة',
    approve: 'الموافقة',
    reject: 'الرفض',
    transactionSummary: 'ملخص العملية',
    transactionSummaryDisclaimer: 'كامالك نحتاج التاكيد لي اكمال العملية',
    shareOwnership: 'حصة الملكية',
    email: 'البريد الالكتروني',
    phoneNumber: 'رقم الهاتف',
    transactionType: 'نوع العملية',
    rejectedText: 'لقد رفضت هذي العملية',
    for_sale_share: 'بيع',
    share_transfer: 'تحويل',
    pending: 'قيد الانتظار',
    approved: 'تمت الموافقة',
    rejected: 'تم الرفض',
    canceled: 'تم الالغاء',
    closed: 'مغلق',
    askYourAdminForPermission: 'تواصل مع المسؤول من اجل هذه الخاصبة',
    approvedText: 'تمت الموافقة بنجاح',
    recipient: 'المستلم',
    recipientOwnership: 'حصة الملكية للمستلم',
    transactionRequests: 'طلبات العمليات',
    payToTransfer: 'ادفع للمناقلة',
    redeemable: 'قابلة للاسترداد',
  },

  equity: {
    addingNewEquity: 'إضافة فئة جديدة',
    totalPersonalizedExperience: 'تجربة شخصية كاملة لملفك الشخصي، أضف معلومات شركتك لتحصل على أعلى قيمة من راس مال',
    equityType: 'نوع الأسهم',
    common: 'عادية',
    preferred: 'ممتازة',
    equityNameLabel: 'اسم الفئة',
    suggestedNames: 'الأسماء المقترحة',
    giveEquityNameLabel: 'حدد اسم الفئة',
    boardApprovalDate: 'تاريخ موافقة المجلس',
    addBoardApprovalDate: 'إضافة تاريخ موافقة المجلس',
    sharesAuthorized: 'الأسهم المصرح بها',
    originalIssuePrice: 'سعر الإصدار الأصلي',
    preferences: 'التفضيلات',
    seniorityLevel: 'مستوى الأقدمية',
    conversionRatio: 'نسبة التحويل',
    multiple: 'مكرر',
    liquidationPreference: 'مميزات التخارج',
    participatingRights: 'حقوق المشاركة',
    preMoneyValuation: 'تقييم ما قبل الاستثمار',
    comment: 'تعليق',
    commentPlaceholder: 'أترك أي تعليق أو ملاحظة',
    submit: 'إرسال',
    back: 'الرجوع',
    cancel: 'الغاء',
    participatingCapMultiple: 'مكرر سقف المشاركة',
    'Pre-seed Round': 'Pre-seed Round',
    'Seed Round': 'Seed Round',
    'Series A': 'الجولة أ',
    'Series B': 'الجولة ب',
    'Series C': 'الجولة ج',
    'Series D': 'الجولة د',
    redeemable: 'قابلة للاسترداد',
  },

  options: {
    captable: 'جدول حصص الملكية',
    options: 'خيارات الاسهم',
    pools: 'خطط خيارات الأسهم',
    addNewPool: 'إضافة خطة جديد',
    empowerYourEmployees: 'مكّن موظفيك',
    emptyStateDescription:
      'في الخطوة التالية، ستكون قادرًا على إنشاء خطط ومجموعات لمنح خيارات الأسهم لموظفيك ومستشاريك. ونوصي بإعداد جميع المعلومات المتعلقة بخيارات الأسهم وخطط الموظفين قبل النقر الانتقال للخطوة التالية.',
    poolName: 'اسم الخطة',
    ownershipFDPercentage: 'نسبة الملكية (FD) ٪',
    actions: 'خيارات',
    grantOptions: 'منح خيارات أسهم',
    optionsSecurities: 'خطط خيارات الأسهم',
    optionsDetails: 'تفاصيل خيارات الأسهم',
    boardApprovalDate: 'تاريخ موافقة المجلس',
    optionsOutstanding: 'خيارات الأسهم الممنوحة',
    reservedOptions: 'الخيارات المحجوزة',
    authorizedOptions: 'الخيارات المصرح بها',
    availableShares: 'الأسهم المتاحة',
    availableOptions: 'الخيارات المتاحة',
    shareholder: 'المساهم',
    prefix: 'الاختصار',
    afterCreatingOptionsPlan: 'بعد إنشاء خطة خيارات الأسهم، ستتمكن من منح خيارات الأسهم لموظفيك ومستشاريك',
    suggestedNames: 'الأسماء المقترحة',
    preMoneyValuation: 'تقييم ما قبل الاستثمار',
    comment: 'تعليق',
    commentPlaceholder: 'أترك أي تعليق أو ملاحظة',
    submit: 'إرسال',
    back: 'الرجوع',
    cancel: 'الغاء',
    sharesAuthorized: 'الأسهم المصرح بها',
    sharesAuthorizedPlaceholder: 'إضافة عدد الأسهم المحجوزة/المصرحة لفئة خيارات الأسهم',
    selectDate: 'اختر التاريخ',
    poolNamePlaceholder: 'تسمية التجميع',
    ESOP: 'اسهم الموظفين',
    'Advisory Pool': 'اسهم المستشارين',
    issueOption: 'إصدار خيارات الأسهم',
    vested: 'مستحق',
    remainingToVest: 'متبقي الاستحقاق',
    exercisePrice: 'سعر التنفيذ',
    totalCost: 'اجمالي التكلفة',
    vestingSchedule: 'جدول استحقاق الملكية',
    addNewShareholder: 'إضافة مساهم جديد',
    grantName: 'اسم المنحة',
    years: 'سنوات',
    optionsAvailableToGrant: 'خيارات أسهم متاحة للمنح',
    addNewVestingSchedule: 'أضف جدول استحقاق جديد',
    setVestingSchedule:
      'قم بتحديد جدول الاستحقاق لمنح موظفيك حقوق الملكية الكاملة للأصول المقدمة من جهة العمل مع مرور الوقت',
    issuanceDate: 'تاريخ الإصدار',
    selectDateOfIssuance: 'تحديد تاريخ الإصدار',
    sharesIssued: 'خيارات الأسهم الممنوحة',
    createNewOption: 'إنشاء خيار جديد',
    securityName: 'اسم الورقة المالية',
    shareReserved: 'السهم المحجوز',
    companySecurityOption: 'الشركة / الورقة المالية / الخيار',
    className: 'اسم الفئة',
    sharesReserved: 'الأسهم المحجوزة',
    boardApprovalDateIsRequired: 'تاريخ موافقة مجلس الإدارة مطلوب',
    optionNameIsRequired: 'اسم الخيار مطلوب',
    equityIsRequired: 'الملكية مطلوبة',
    totalGranted: 'الخيارات الممنوحة',
    underlyingSecurity: 'اسم الفئة',
    optionGrantsOverview: 'تفاصيل خيارات الممنوحة',
    poolDetails: 'تفاصيل خيارات الأسهم',
    vestedPercentage: 'نسبة الاستحقاق',
    expirationPeriod: 'فترة صلاحية الخيارات',
    vestingStartDate: 'تاريخ بداية الاستحقاق',
    addOptionsAndESOPs: 'إضافة خيارات الأسهم واسهم الموظفين',
    vestingInfo: 'معلومات الاستحقاق',
    cliff: 'منحنى',
    duration: 'المدة',
    frequency: 'التكرار',
    upfrontVestingAmount: 'الاستحقاق المسبق',
    months: 'أشهر',
  },

  instruments: {
    convertibleInstruments: 'السندات القابلة للتحويل',
    captable: 'جدول حصص الملكية',
    addConvertibleInstruments: 'إضافة السندات القابلة للتحويل',
    instrumentHolder: 'حامل السند',
    prefix: 'الاختصار',
    principal: 'أصل الدين',
    interest: 'الفائدة',
    valuationCap: 'سقف التقييم',
    discountPercentage: 'الخصم (٪)',
    issueDate: 'تاريخ الإصدار',
    actions: 'خيارات',
    instrumentName: 'اسم السند',
    maturityDate: 'تاريخ الاستحقاق',
    comment: 'تعليق',
    earlyExitMultiple: 'مضاعف التخارج المبكر',
    conversionTriggerAmount: 'المبلغ المؤدي للتحويل',
    close: 'إغلاق',
    investorName: 'اسم المستثمر',
    addNewShareholder: 'إضافة مساهم جديد',
    suggestedNames: 'الأسماء المقترحة',
    principalAmount: 'مبلغ اصل الدين',
    instrumentNamePlaceholder: 'حدد المستثمر لإصدار السند القابل للتحويل ',
    convertibleInstrumentName: 'اسم السند القابل للتحويل',
    selectDateOfIssuance: 'تحديد تاريخ الإصدار',
    valuationCapPlaceholder: 'أضف الحد الأقصى، تقدير متى ستتحول السندات إلى أسهم',
    discountPlaceholder: 'أضف الخصم على سعر السهم في الجولة التالية المستخدمة عند تحويل السندات إلى أسهم',
    annualInterestRate: '	معدل الفائدة السنوي %',
    annualInterestRatePlaceholder: 'أضف معدل الفائدة للسندات القابلة للتحويل',
    selectDate: 'اختر التاريخ',
    commentPlaceholder: 'أترك أي تعليق أو ملاحظة',
    conversionTriggerAmountPlaceholder: 'أضف الحد الأدنى لجمع التمويل الذي ستتحول فيه السندات إلى أسهم',
    earlyExitMultiplePlaceholder: 'أضف الضعف المعطى على سعر شراء السندات في حالة حدوث تخارج قبل تحويل المذكرة إلى أسهم',
    SAFE: 'SAFE',
    KISS: 'KISS',
    'Convertible Note': 'السند القابل للتحويل',
    customizeConvertibleTerms:
      'قم بتخصيص شروط السندات القابلة للتحويل إلى أي نوع: SAFE او KISS، أوNote. *وقد لا تتضمن بعض الشروط في سنداتك القابلة للتحويل.',
    addNewConvertibleInstrument: 'اضافة السندات الجديدة القابلة للتحويل',
    issueConvertibleNotes: 'اصدار السندات القابلة للتحويل SAFE - KISS',
    recommendPrepareConvertibleDetails:
      'نوصيك بتجهيز بيانات السندات القابلة للتحويل مثل صاحب السند، تاريخ اصدار السند، وذلك لمساعدتك خلال الخطوة القادمة.',
    submit: 'إرسال',
    back: 'الرجوع',
    name: 'الاسم',
    convert: 'تحويل',
    converted: 'تم التحويل',
  },

  deleteModal: {
    deleteWarning: 'انت على وشك حذف شئ مهم',
    deleteConfirmation: 'هل متأكد من حذف',
    impactCapTableWarning:
      'قد تؤثر هذه الخطوة على جدول الرسملة الخاص بك بشكل عام، مما قد يتسبب في أضرار وتغييرات في الأسهم الأخرى.',
    delete: 'حذف',
    yes: 'نعم',
    cancel: 'الغاء',
  },

  history: {
    captable: 'جدول حصص الملكية',
    transactionsHistory: 'سجل العمليات',
    entityID: 'رقم العملية',
    actionType: 'نوع العملية',
    approvalDate: 'تاريخ الموافقة',
    amount: 'عدد',
    transaction: 'الاسم',
    shareholderName: 'اسم المستثمر',
    granted_options: 'إصدار خيارات اسهم',
    convertible_instruments: 'إصدار سند قابل للتحويل',
    issue_share: 'إصدار أسهم',
    options: 'إصدار حطة خيارات أسهم',
  },
  myActivity: {
    myActivity: 'نشاطاتي',
    personalHoldings: 'نظرة عامة على الاستثمار',
    activitySummary: 'ملخص النشاط',
    numberOfTransactions: 'عدد المعاملات',
    totalSellShares: 'إجمالي الأسهم المباعة',
    totalTransferShares: 'إجمالي الأسهم المحولة',
    totalPrice: 'السعر الإجمالي',
    share: 'سهم',
    shares: 'أسهم',
    myTransactions: 'معاملاتي',
    type: 'النوع',
    status: 'الحالة',
    numberOfShares: 'عدد الأسهم',
    total: 'الإجمالي',
    pricePerShare: 'السعر/السهم',
    interested: 'مهتم',
    actions: 'خيارات',
    sell: 'بيع',
    transfer: 'تحويل',
    approved: 'موافق عليه',
    pending: 'قيد الانتظار',
    rejected: 'مرفوض',
    cancel: 'إلغاء',
    canceled: 'ملغي',
    open: 'مفتوح',
    seeInterested: 'عرض المهتمين',
    new: 'جديد',
    delete: 'حذف',
    note: 'ملاحظة',
    for_sale_share: 'بيع',
    share_transfer: 'تحويل',
    interestedUsers: 'المستخدمون المهتمون',
    userInterested: 'مستخدم مهتم',
    usersInterested: 'مستخدمون مهتمون',
    nameOfBuyer: 'اسم المشتري',
    phoneNumber: 'رقم الهاتف',
    email: 'البريد الإلكتروني',
    reject: 'رفض',
    approve: 'موافقة',
    close: 'إغلاق',
    closed: 'مغلق',
    requestPending: 'الطلب قيد الانتظار',
    transferShares: 'تحويل الأسهم',
    finalStep: 'الخطوة النهائية',
    beforeCompletingProcess: 'قبل إكمال هذه العملية',
    ensureValueTransferred: 'تأكد من أنه تم تحويل القيمة المفترضة للأسهم قبل إكمال العملية.',
    back: 'عودة',
    confirm: 'تأكيد',
    myPurchases: 'مشترياتي',
    owner: 'المالك',
    uploadProof: 'تحميل الإثبات',
    viewDraftContract: 'عرض العقد المبدئي',
    downloadContract: 'تحميل العقد',
    cancelInterested: 'إلغاء الاهتمام',
    waiting_payment_proof: 'في انتظار إثبات الدفع',
    payment_proof_uploaded: 'تم تحميل إثبات الدفع',
    awaiting_signatures: 'في انتظار التوقيعات',
    contract_signed: 'تم التوقيع',
    viewProof: 'عرض الإثبات',
    awaiting_signature: 'في انتظار التوقيع',
    dragAndDrop: 'اسحب وأفلت',
    or: 'أو',
    chooseFile: 'اختر ملفًا',
    next: 'التالي',
    contractTerms: 'شروط العقد',
    uploadingTransferReceipt: 'جاري تحميل إيصال التحويل',
    terms: {
      introduction: {
        title: '1. المقدّمة',
        content:
          'مرحبًا بكم في راسمال. باستخدام منصتنا لتسجيل وعرض شركتك للبيع، فإنك توافق على الشروط والأحكام التالية. يرجى قراءتها بعناية.',
      },
      eligibility: {
        title: '2. الأهلية',
        content:
          'لتتمكن من عرض شركتك للبيع، يجب أن لا يقل عمرك عن 18 عامًا وأن تكون لديك الصلاحية القانونية لتمثيل الشركة.',
      },
      listingProcess: {
        title: '3. عملية العرض',
        content: 'تتعهد بتقديم معلومات دقيقة وكاملة حول شركتك.',
        informationAccuracy: {
          title: 'دقة المعلومات',
          content: 'تتعهد بتقديم معلومات دقيقة وكاملة حول شركتك.',
        },
        dataSharing: {
          title: 'مشاركة البيانات',
          content:
            'توافق على مشاركة بيانات محددة عن شركتك مع المستثمرين المحتملين، وذلك وفقًا لاتفاقية مشاركة البيانات.',
        },
        approval: {
          title: 'الموافقة',
          content:
            'جميع العروض خاضعة للمراجعة والموافقة من فريقنا، ونحتفظ بالحق في رفض أو إزالة أي عرض لا يستوفي معاييرنا.',
        },
      },
      confidentiality: {
        title: '4. السرية',
        content:
          'نلتزم بحماية خصوصيتك ولن نشارك معلومات شركتك إلا مع المستثمرين المهتمين الذين وافقوا على الحفاظ على السرية. لن يتم الكشف عن معلوماتك لأي أطراف غير مصرح لها.',
      },
      userConduct: {
        title: '5. سلوك المستخدم',
        content:
          'توافق على عدم الانخراط في أي نشاط قد يضر بالمنصة أو مستخدميها، بما في ذلك على سبيل المثال لا الحصر: تقديم معلومات خاطئة أو مضللة، أو الانخراط في أنشطة احتيالية، أو انتهاك أي قوانين أو لوائح سارية.',
      },
      feesAndPayments: {
        title: '6. الرسوم والمدفوعات',
        content: '[تفاصيل حول الرسوم المتعلقة بعرض الشركة للبيع، إن وجدت].',
        listingFees: {
          title: 'رسوم العرض',
          content: '[تفاصيل حول الرسوم المتعلقة بعرض الشركة للبيع، إن وجدت].',
        },
        transactionFees: {
          title: 'رسوم المعاملات',
          content: '[تفاصيل حول الرسوم المتعلقة بالمعاملات الناجحة، إن وجدت].',
        },
      },
      termination: {
        title: '7. الإنهاء',
        content:
          'نحتفظ بالحق في إنهاء وصولك إلى المنصة إذا انتهكت هذه الشروط والأحكام أو شاركت في أي نشاط يضر بالمنصة أو مستخدميها.',
      },
      limitationOfLiability: {
        title: '8. تحديد المسؤولية',
        content:
          'لا يتحمّل [اسم المنصة] مسؤولية أي أضرار مباشرة أو غير مباشرة تنتج عن استخدامك للمنصة، بما في ذلك على سبيل المثال لا الحصر الخسائر المالية أو اختراق البيانات أو خسارة الفرص التجارية.',
      },
      disputeResolution: {
        title: '9. حل النزاعات',
        content:
          'يتم حل أي نزاعات تنشأ عن استخدامك للمنصة عن طريق التحكيم الملزم وفقًا لقواعد [منظمة التحكيم]. وتوافق على التنازل عن حقك في المحاكمة أمام هيئة محلفين.',
      },
      changesToTerms: {
        title: '10. تغييرات على الشروط',
        content:
          'نحتفظ بالحق في تعديل هذه الشروط والأحكام في أي وقت. تصبح أي تغييرات سارية فور نشرها. يؤدي استمرارك في استخدام المنصة إلى قبولك لهذه التعديلات.',
      },
      contactUs: {
        title: '11. اتصل بنا',
        content:
          'إذا كانت لديك أي استفسارات أو مخاوف بخصوص هذه الشروط والأحكام، يرجى التواصل معنا على [بيانات الاتصال].',
      },
      agreeToAllTermsAndConditions: 'أوافق على جميع الشروط والأحكام',
    },
    payToApprove: 'ادفع للموافقة',
  },

  contracts: {
    contracts: 'العقود',
    personalHoldings: 'نظرة عامة على الاستثمار',
    myContract: 'عقودي',
    contractStatus: 'حالة العقد',
    partiesInvolved: 'الأطراف المشاركة',
    statusForEachParty: 'الحالة لكل طرف',
    party1Name: 'اسم الطرف 1',
    party2Name: 'اسم الطرف 2',
    connectionDetails: 'تفاصيل الاتصال',
    actions: 'خيارات',
    pending: 'قيد الانتظار',
    rejected: 'مرفوض',
    completed: 'مكتمل',
    downloadContract: 'تحميل العقد',
    viewContract: 'عرض العقد',
    buyer: 'المشتري',
    seller: 'البائع',
    sell: 'بيع',
    transfer: 'تحويل',
    other: 'أخرى',
    you: 'أنت',
    signed: 'موقَّع',
    signature: 'توقيع',
    id: 'معرف',
    type: 'النوع',
    status: 'الحالة',
    for_sale_share_request: 'بيع',
    share_transfer_request: 'تحويل',
    close: 'إغلاق',
    date: 'التاريخ',
  },
};
