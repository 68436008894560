import themeToken from '@lib/theme/tokens/index';
import { Flex, Typography } from 'antd';
import React from 'react';
import { UserPlanCardContainer } from './UserPlanCardContainer';
import UserPlanProgressItem from './UserPlanProgressItem';

const { Text } = Typography;

type UserPlanHeaderCardsProps = {
  title: string;
  icon: React.ReactNode;
  used?: number;
  limit?: number;
  progressInfo: React.ReactNode;
};

const UserPlanHeaderCards = ({ title, icon, used, limit, progressInfo }: UserPlanHeaderCardsProps) => {
  return (
    <UserPlanCardContainer>
      <Flex gap={8} align="center" style={{ marginBottom: themeToken.marginSM }}>
        {icon}
        <Text
          style={{
            color: themeToken.black,
            fontWeight: 600,
            fontSize: themeToken.fontSizeHeading5,
          }}
        >
          {title}
        </Text>
      </Flex>
      <UserPlanProgressItem title={limit === 0 ? title : null} used={used} limit={limit} progressInfo={progressInfo} />
    </UserPlanCardContainer>
  );
};

export default UserPlanHeaderCards;
