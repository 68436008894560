export default {
  dataRoom: 'Data Room',
  main: 'Main',
  allCompanyFiles: 'All Company Files',
  yourFiles: 'Your files',
  sharedLogs: 'Shared Logs',
  description: 'Description...',
  searchFileDocuments: 'Search file, Documents etc.',
  fileName: 'File Name',
  sharedBy: 'Shared By',
  sharedTo: 'Shared To',
  expiryDate: 'Expiry Date',
  expiredDate: 'Expired Date',
  sharedDate: 'Shared Date',
  dataNotFound: 'Data Not Found',
  analytics: 'Analytics',
  newFolder: 'New Folder',
  share: 'Share',
  delete: 'Delete',
  settings: 'Settings',
  createNewFolder: ' Create New Folder',
  folderName: 'Folder Name',
  download: 'Download',
  back: 'Back',
  submit: 'Submit',
  watermark: 'Watermark',
  fieldRequired: 'This field is required',
  userPermissions: 'User Permissions',
  shareFile: 'Share File',
  selectDate: 'Select date',
  email: 'Email',
  receivers: 'Receivers',
  'shared logs': 'Shared Logs',
  receiverEmail: 'Receiver Email',
  deleteWarning: 'You are about to delete something important',
  deleteConfirmation: 'Are you certain about deleting the',
  file: 'File',
  yesDelete: 'Yes, Delete',
  viewCount: 'View Count',
  previewDuration: 'Duration Of Preview',
  downloadCount: 'Download Count',
  lastModified: 'Last Modified',
  uploadFile: 'Upload File',
  rename: 'Rename',
  open: 'Open',
  copyTo: 'Copy to',
  copyHere: 'Copy Here',
  chooseFolderToCopyTo: 'Choose Folder to copy to',
  yes: 'Yes',
  cancel: 'Cancel',
  folderOptions: 'Folder Options',
  downloadable: 'Downloadable',
  watermarked: 'Watermarked',
  shareExpiryDate: 'Share Expiry Date',
  selectAll: 'Select All',
  itemSelected: '{{count}} Item Selected',
  itemsSelected: '{{count}} Items Selected',
  zoom: 'Zoom',
  previous: 'Previous',
  next: 'Next',
  page: 'Page',
  goTo: 'Go To',
  pageOf: 'Page {{pageNumber}} of {{pdfPages}}',
  halt: 'Halt',
  haltWarningModalTitle: 'Halt Shared File',
  haltWarningModalContent: 'Are you sure you want to halt this shared file? This action cannot be undone.',
  extend: 'Extend',
  extendModalTitle: 'Extend Shared Log',
  extendModalContent: 'Choose a date to extend to',
  confirm: 'Confirm',
};
