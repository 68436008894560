import { axiosClient } from '@/lib/axios';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';

type UsedStorageResponse = {
  data: {
    totalUsedStorage: number;
  };
};

export const getUsedStorage = async ({ companyID }: { companyID: number }): Promise<{ totalUsedStorage: number }> => {
  const { data } = await axiosClient.get<UsedStorageResponse>(`/companies/${companyID}/data-rooms/used-storage`);
  return data?.data;
};

type UseGetUsedStorageOptions = {
  companyID: number;
  config?: Partial<UseQueryOptions<{ totalUsedStorage: number }>>;
};

export const useGetUsedStorage = ({ companyID, config }: UseGetUsedStorageOptions) => {
  return useQuery({
    queryKey: ['used-storage'],
    queryFn: () => getUsedStorage({ companyID }),
    ...config,
  });
};
