import { Flex, Typography } from 'antd';
import { UserPlanCardContainer } from './UserPlanCardContainer';
import themeToken from '@lib/theme/tokens/index';
import UserPlanProgressItem, { UserPlanProgressItemProps } from './UserPlanProgressItem';

const { Text } = Typography;

type UserPlanFeatureUsageContainerProps = {
  title: string;
  icon: React.ReactNode;
  progressItems?: UserPlanProgressItemProps[];
};

const UserPlanFeatureUsageContainer = ({ title, icon, progressItems }: UserPlanFeatureUsageContainerProps) => {
  return (
    <UserPlanCardContainer>
      <Flex gap={8} align="center" style={{ marginBottom: themeToken.marginSM }}>
        {icon}
        <Text
          style={{
            color: themeToken.black,
            fontWeight: 600,
            fontSize: themeToken.fontSizeHeading5,
          }}
        >
          {title}
        </Text>
      </Flex>
      <Flex vertical gap={8}>
        {progressItems?.map((progressItem, index) => <UserPlanProgressItem key={index} {...progressItem} />)}
      </Flex>
    </UserPlanCardContainer>
  );
};

export default UserPlanFeatureUsageContainer;
