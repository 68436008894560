import styled from 'styled-components';
import { Modal } from 'antd';

import themeToken from '@lib/theme/tokens/index';

export const StyledPaymentModal = styled(Modal)`
  .ant-modal-content {
    padding: 24px;
    .ant-input-wrapper {
      input {
        border-inline-end: 0;
      }
      .ant-input-group-addon {
        background: transparent;
        & > .ant-btn {
          color: ${themeToken['branding-secondary-6']};
          font-weight: ${themeToken.fontWeightStrong};
        }
      }
    }
  }
`;
