import { App } from 'antd';
import { axiosClient } from '@/lib/axios';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

type PaymentPayload = {
  companyID: number;
  forSaleShareID: number;
  interestID: number;
  body: {
    code?: string;
  };
};

type PaymentResponse = {
  redirect_url: string;
};

export const sellSharesPayment = async ({
  companyID,
  forSaleShareID,
  interestID,
  body,
}: PaymentPayload): Promise<PaymentResponse> => {
  const response = await axiosClient.post<PaymentResponse>(
    `/companies/${companyID}/cap-table/equities/issued-shares/for-sale/${forSaleShareID}/interests/${interestID}/payment`,
    body,
  );

  return response.data;
};

type UseSellSharesPaymentOptions = {
  config?: Partial<UseMutationOptions<PaymentResponse, AxiosError<{ message: string }>, PaymentPayload>>;
  postSuccess?: (redirectUrl: string) => void;
};

export const useSellSharesPayment = ({ config, postSuccess }: UseSellSharesPaymentOptions) => {
  const { notification: toaster } = App.useApp();
  return useMutation({
    onError: (error) => {
      toaster.error({
        message: 'Something Went Wrong',
        description: error.message,
      });
    },
    onSuccess: async (data) => {
      postSuccess?.(data.redirect_url);
    },
    ...config,
    mutationFn: sellSharesPayment,
  });
};
