import { useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Flex, Divider, Typography, Image } from 'antd';

import { StyledButton } from '@/lib/theme/components/Button';
import { StyledInput } from '@/lib/theme/components/Input';
import { useSelectedCompanyStore } from '@/stores/selectedCompany';
import CompanyLogoPremiumIcon from '@/components/Icons/CompanyLogoPremiumIcon';
import { PremiumCompanyLogoContainer } from '@/components/Shared/PremiumCompanyLogoContainer';
import { LimitsCheckerContext } from '@/components/LimitsChecker';
import { StyledPaymentModal } from '@/components/Shared/StyledPaymentModal';
import { CellSkeleton } from '@/components/Shared/CellSkeleton';
import { formatCurrencyNumber } from '@/utils/formatCurrencyNumber';
import themeToken from '@lib/theme/tokens/index';

import { useGetCompany } from '../../api/getCompany';
import { useGetPaymentPrice } from '../../api/payment/getPaymentPrice';
import { useApplyPromo } from '../../api/payment/applyPromo';
import { useSellSharesPayment } from '../../api/payment/sellSharesPayment';
import { useTransferSharesPayment } from '../../api/payment/transferSharesPayment';

const { Title, Text } = Typography;

type Props = {
  isOpen: boolean;
  handleCancel: () => void;
  forSaleShareID: number;
  selectedInterestID: number;
  numberOfShares?: number;
  isSell?: boolean;
};

const PaymentModal = ({ isOpen, handleCancel, forSaleShareID, selectedInterestID, numberOfShares, isSell }: Props) => {
  const { t } = useTranslation('plans');
  const { recheckLimits } = useOutletContext<LimitsCheckerContext>() || {};

  const [promoCode, setPromoCode] = useState<string>();
  const [isPromoCodeVerified, setIsPromoCodeVerified] = useState(false);
  const { selectedCompany } = useSelectedCompanyStore((state) => state);

  const { data: companyData } = useGetCompany({
    companyID: selectedCompany?.cid as number,
  });
  const { data: paymentData, isLoading: isPaymentPriceLoading } = useGetPaymentPrice({
    companyId: selectedCompany?.cid as number,
  });
  const {
    mutate: applyPromo,
    isPending: isApplyPromoLoading,
    data: applyPromoData,
  } = useApplyPromo({
    postSuccess: () => setIsPromoCodeVerified(true),
  });
  const { mutate: sellSharesPayment, isPending: isSellSharesPaymentLoading } = useSellSharesPayment({
    postSuccess: (redirectUrl) => {
      recheckLimits();
      const link = document.createElement('a');
      link.href = redirectUrl;
      link.click();
    },
  });
  const { mutate: transferSharesPayment, isPending: isTransferSharesPaymentLoading } = useTransferSharesPayment({
    postSuccess: (redirectUrl) => {
      recheckLimits();
      const link = document.createElement('a');
      link.href = redirectUrl;
      link.click();
    },
  });

  const currencyCode = companyData?.company_data?.company_currency.code ?? '';

  const handlePaymentModalCancel = () => {
    handleCancel();
  };
  const addPromoCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPromoCode(e.target.value);
  };

  const handleApplyPromoCode = () => {
    if (promoCode)
      applyPromo({
        companyID: selectedCompany?.cid as number,
        body: {
          code: promoCode,
        },
      });
  };

  const resetPromo = () => {
    setPromoCode(() => '');
    setIsPromoCodeVerified(false);
  };

  const handleSharesPayment = () => {
    if (selectedCompany?.cid) {
      if (isSell)
        sellSharesPayment({
          companyID: selectedCompany?.cid,
          forSaleShareID,
          interestID: selectedInterestID,
          body: {
            code: promoCode,
          },
        });
      else if (numberOfShares)
        transferSharesPayment({
          companyID: selectedCompany?.cid,
          body: {
            issue_share_id: forSaleShareID,
            number_of_shares: numberOfShares,
            shareholder_id: selectedInterestID,
            code: promoCode,
          },
        });
    }
  };

  return (
    <StyledPaymentModal
      open={isOpen}
      onCancel={handlePaymentModalCancel}
      width={500}
      footer={null}
      centered
      destroyOnClose
    >
      <Title
        style={{
          color: themeToken.colorPrimary,
          fontSize: `${themeToken.fontSizeLG}px`,
          fontWeight: '600',
        }}
      >
        {t('addOnPayment')}
      </Title>
      <Divider
        style={{
          marginBlock: themeToken.margin,
        }}
      />
      <Flex gap={24} vertical>
        <Flex align="center" justify="space-between">
          <Flex gap={8} align="center" justify="space-between">
            <PremiumCompanyLogoContainer>
              <Flex style={{ position: 'absolute', top: '-5px', right: '-5px', zIndex: 10 }}>
                <CompanyLogoPremiumIcon />
              </Flex>
              <Image
                preview={false}
                src={companyData?.company_data?.logo}
                alt={companyData?.company_data?.name}
                width={36}
                height={36}
                placeholder
              />
            </PremiumCompanyLogoContainer>
            <Flex vertical>
              <Title
                style={{
                  fontSize: `${themeToken.fontSizeLG}px`,
                  fontWeight: `${themeToken.fontWeightLG}`,
                  color: themeToken['branding-natural-9'],
                }}
              >
                {selectedCompany?.name}
              </Title>
              <Text
                style={{
                  fontSize: `${themeToken.fontSizeLG}px`,
                  fontWeight: `${themeToken.fontWeightNormal}`,
                  color: themeToken['branding-natural-9'],
                }}
              >
                {`${isSell ? t('sellPayment') : t('transferPayment')}`}
              </Text>
            </Flex>
          </Flex>
          <Flex vertical align="end">
            {isPaymentPriceLoading ? (
              <CellSkeleton block={false} />
            ) : (
              <Text
                style={{
                  fontSize: `${themeToken.fontSizeHeading3}px`,
                  fontWeight: `${themeToken.fontWeightLG}`,
                  color: themeToken['branding-natural-10'],
                }}
              >
                {formatCurrencyNumber(paymentData?.price ?? 0, currencyCode)}
              </Text>
            )}
          </Flex>
        </Flex>

        <Divider
          style={{
            marginBlock: 0,
          }}
        />
        <Flex gap={4} vertical>
          <Title
            level={5}
            style={{
              fontSize: themeToken.fontSize,
              marginBlockEnd: themeToken.marginXXS,
            }}
          >
            {t('promoCode')}
          </Title>
          <StyledInput
            disabled={isPromoCodeVerified}
            addonAfter={
              <StyledButton
                loading={isApplyPromoLoading}
                type="text"
                size="small"
                onClick={isPromoCodeVerified ? resetPromo : handleApplyPromoCode}
                style={{ background: 'transparent' }}
              >
                {isPromoCodeVerified ? t('reset') : t('apply')}
              </StyledButton>
            }
            placeholder={t('enterPromoCode')}
            size="large"
            value={promoCode}
            onChange={addPromoCode}
          />
        </Flex>
        <Flex gap={12} vertical>
          <Title
            level={5}
            style={{
              fontSize: themeToken.fontSize,
              marginBlockEnd: themeToken.marginXXS,
            }}
          >
            {t('summary')}
          </Title>
          <Flex align="center" justify="space-between">
            <Text
              style={{
                color: themeToken['branding-natural-6'],
              }}
              strong
            >
              {t('plan')}
            </Text>
            {isPaymentPriceLoading ? (
              <CellSkeleton block={false} />
            ) : (
              <Text
                style={{
                  fontSize: themeToken.fontSizeLG,
                }}
                strong
              >
                {formatCurrencyNumber(paymentData?.price ?? 0, currencyCode, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </Text>
            )}
          </Flex>
          <Flex align="center" justify="space-between">
            <Text
              style={{
                color: themeToken['branding-natural-6'],
              }}
              strong
            >
              {t('discount')}
            </Text>
            {isPaymentPriceLoading ? (
              <CellSkeleton block={false} />
            ) : (
              <Text
                style={{
                  fontSize: themeToken.fontSizeLG,
                  color: themeToken['branding-natural-6'],
                }}
                strong
              >
                {formatCurrencyNumber(
                  promoCode && isPromoCodeVerified && applyPromoData?.price
                    ? (paymentData?.price ?? 0) - applyPromoData?.price
                    : 0,
                  currencyCode,
                  {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  },
                )}
              </Text>
            )}
          </Flex>
          <Divider
            style={{
              margin: 0,
            }}
          />
          <Flex align="center" justify="space-between">
            <Text
              style={{
                color: themeToken['branding-natural-6'],
              }}
              strong
            >
              {t('total')}
            </Text>
            {isPaymentPriceLoading ? (
              <CellSkeleton block={false} />
            ) : (
              <Text
                style={{
                  fontSize: themeToken.fontSizeLG,
                }}
                strong
              >
                {formatCurrencyNumber(
                  promoCode && isPromoCodeVerified && applyPromoData?.price
                    ? applyPromoData?.price
                    : paymentData?.price ?? 0,
                  currencyCode,
                  {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  },
                )}
              </Text>
            )}
          </Flex>
        </Flex>
      </Flex>
      <StyledButton
        block
        disabled={isSellSharesPaymentLoading || isTransferSharesPaymentLoading}
        type="primary"
        style={{
          marginBlockStart: themeToken.margin,
        }}
        onClick={handleSharesPayment}
      >
        {t('goToPayment')}
      </StyledButton>
    </StyledPaymentModal>
  );
};

export default PaymentModal;
