import { UseMutationOptions, useQuery } from '@tanstack/react-query';

import axiosClient from '@/lib/axios';
import { AxiosError } from 'axios';

type PaymentPriceParams = {
  companyId: number;
};

type PaymentPrice = {
  price: number;
};

export const getPaymentPrice = async ({ companyId }: PaymentPriceParams): Promise<PaymentPrice> => {
  const response = await axiosClient.post<{ data: PaymentPrice }>('/premium/subscription/limitations/price', {
    key: 'limits.shares.sell',
    companyId,
  });

  return response.data.data;
};

type UseGetPaymentPriceOptions = {
  companyId: number;
  config?: Partial<UseMutationOptions<PaymentPrice, AxiosError<{ message: string }>, PaymentPriceParams>>;
  postSuccess?: () => void;
};
export const useGetPaymentPrice = ({ companyId, config }: UseGetPaymentPriceOptions) => {
  return useQuery({
    queryKey: ['payment-price', companyId],
    queryFn: () => getPaymentPrice({ companyId }),
    ...config,
  });
};
