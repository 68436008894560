import { PaymentHistory } from '@/features/premium/types';
import { axiosClient } from '@/lib/axios';
import { Metadata, PaginationParams } from '@/types';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';

type ProfileUpdateResponse = {
  data: PaymentHistory[];
  metadata: Metadata;
};

type PaymentHistoryParams = {
  companyID: number;
  queryParams?: PaginationParams;
};

export const getPaymentHistory = async ({
  companyID,
  queryParams,
}: PaymentHistoryParams): Promise<PaymentHistory[]> => {
  const { data } = await axiosClient.get<ProfileUpdateResponse>(`/payment-history/${companyID}`, {
    params: queryParams,
  });
  return data?.data;
};

type UseGetPaymentHistoryOptions = {
  companyID: number;
  queryParams?: PaymentHistoryParams['queryParams'];
  config?: Partial<UseQueryOptions<PaymentHistory[]>>;
};

export const useGetPaymentHistory = ({ companyID, queryParams, config }: UseGetPaymentHistoryOptions) => {
  return useQuery({
    queryKey: ['payment-history', companyID],
    queryFn: () => getPaymentHistory({ companyID, queryParams }),
    ...config,
  });
};
