import { Flex } from 'antd';
import styled from 'styled-components';
import themeToken from '@lib/theme/tokens/index';

export const PremiumCompanyLogoContainer = styled(Flex)`
  width: 48px;
  height: 48px;
  border: 1px solid ${themeToken['branding-natural-1']};
  border-radius: 4px;
  padding: 6px;
  position: relative;
  display: flex;
  align-items: center;
`;
