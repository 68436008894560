const StorageIcon = ({ width, height }: { width?: number; height?: number }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 16 16" fill="none">
    <path
      d="M2.33337 4.66634V11.333C2.33337 13.333 3.31337 14.6663 5.66671 14.6663H10.3334C12.6467 14.6663 13.6667 13.333 13.6667 11.333V10.553C13.6667 10.1997 13.5267 9.85967 13.2734 9.61301L12.72 9.05967C12.4667 8.80634 12.3267 8.47301 12.3267 8.11967V6.66634C12.3267 6.29967 12.6267 5.99967 12.9934 5.99967C13.36 5.99967 13.66 5.69967 13.66 5.33301V4.66634C13.66 2.66634 12.64 1.33301 10.3267 1.33301H5.66004C3.31337 1.33301 2.33337 2.66634 2.33337 4.66634Z"
      stroke="#434750"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.32661 14.6463L5.31995 12.6663C5.31995 11.9263 5.91328 11.333 6.65328 11.333H9.32661C10.0599 11.333 10.6533 11.9263 10.6599 12.6597L10.6799 14.653"
      stroke="#434750"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.95996 3.29971L5.99329 1.37305"
      stroke="#434750"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.95996 3.29971L7.99329 1.37305"
      stroke="#434750"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.95996 3.28035L9.99329 1.36035"
      stroke="#434750"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default StorageIcon;
