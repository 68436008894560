import { useMemo, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Divider, Flex, Form } from 'antd';

import CommonModal from '@/features/captable/components/Shared/CommonModal';
import { StyledButton } from '@/lib/theme/components/Button';
import CommonDropDownField from '@/components/Shared/CommonDropDownField';
import { useSelectedCompanyStore } from '@/stores/selectedCompany';
import ShareTransferIcon from '@/components/Icons/ShareTransferIcon';
import { formatNumber } from '@/utils/formatNumber';
import { StyledInputNumber } from '@/features/auth/components';
import { LimitsCheckerContext } from '@/components/LimitsChecker';
import themeToken from '@lib/theme/tokens/index';

import { AddTransferSharePayload, useAddTransferShare } from '../../api/addTransferShare';
import { useGetTransferShareholders } from '../../api/getTranferShareholders';
import PaymentModal from '../myActivity/PaymentModal';
import type { ShareDetail } from '../../types';

type SellSharesModalProps = {
  isOpen: boolean;
  onClose: () => void;
  issueShare: ShareDetail;
};

const TransferSharesModal = ({ isOpen, onClose, issueShare }: SellSharesModalProps) => {
  const [form] = Form.useForm();
  const shareholder_id = Form.useWatch<number>('shareholder_id', form);
  const number_of_shares = Form.useWatch<number>('number_of_shares', form);

  const { t } = useTranslation('captable', {
    keyPrefix: 'overview',
  });
  const { limitations } = useOutletContext<LimitsCheckerContext>() || {};

  const { selectedCompany } = useSelectedCompanyStore((state) => state);

  const [usersListPage, setUsersListPage] = useState(1);
  const [usersListHasNextPage, setUsersListHasNextPage] = useState(true);
  const [usersSearchQuery, setUsersSearchQuery] = useState<string>();
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);

  const { data: usersList, isLoading: isUserListLoading } = useGetTransferShareholders({
    companyID: selectedCompany?.cid as number,
    queryParams: usersSearchQuery
      ? {
          page: usersListPage,
          q: usersSearchQuery,
        }
      : { page: usersListPage },
  });

  const { mutate: addTransferShare, isPending: isAddTransferSharePending } = useAddTransferShare({
    postSuccess: onClose,
  });

  const canTransferShares = useMemo(() => {
    const sharePlanlimit = limitations?.find(({ key }) => key === 'limits.shares.sell');
    if (sharePlanlimit && sharePlanlimit?.usage >= sharePlanlimit?.limit) return false;
    return true;
  }, [limitations]);

  const onAddTransferShare = (values: AddTransferSharePayload['payload']) => {
    const payload = {
      shareholder_id: values.shareholder_id,
      number_of_shares: Number(values.number_of_shares),
      issue_share_id: issueShare.id,
    };
    addTransferShare({
      companyID: selectedCompany?.cid as number,
      payload: payload,
    });
  };

  const handlePaymentModalOpen = () => {
    setIsPaymentModalOpen(true);
  };
  const handlePaymentModalClose = () => {
    setIsPaymentModalOpen(false);
  };

  return (
    <CommonModal isModalOpen={isOpen} handleCancel={onClose}>
      <Flex align="start" gap={8}>
        <div style={{ marginTop: '1%' }}>
          <ShareTransferIcon />
        </div>
        <h2 style={{ color: themeToken.primary_900, fontWeight: 500 }}>{t('transferShares')}</h2>
      </Flex>
      <Divider style={{ margin: '0', marginBottom: '1%' }} />
      <Form form={form} onFinish={onAddTransferShare} layout="vertical">
        <Form.Item
          name="shareholder_id"
          label={t('theReceiverUser')}
          style={{ marginBottom: '0' }}
          rules={[
            {
              required: true,
              message: 'Please select a Receiver',
            },
          ]}
        >
          <CommonDropDownField
            data={usersList?.data}
            isLoading={isUserListLoading}
            metadata={usersList?.metadata}
            optionsHasNextPage={usersListHasNextPage}
            optionsListPage={usersListPage}
            setOptionsHasNextPage={setUsersListHasNextPage}
            setOptionsListPage={setUsersListPage}
            setOptionsSearchQuery={setUsersSearchQuery}
            labelKey="name"
            placeholder={t('shareholder')}
          />
        </Form.Item>
        <Form.Item
          name="number_of_shares"
          label={t('amountOfShares')}
          rules={[
            {
              required: true,
              message: 'Please enter amount of shares',
            },
            {
              type: 'number',
              max: issueShare?.shares_authorized,
              message: `Please set a number less the the available shares`,
            },
          ]}
        >
          <StyledInputNumber
            formatter={(value) => formatNumber(value as number, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
            placeholder={t('amountOfShares')}
          />
        </Form.Item>

        <Flex justify="space-between">
          <StyledButton ghost type="primary" onClick={onClose}>
            {t('back')}
          </StyledButton>
          {canTransferShares ? (
            <StyledButton type="primary" htmlType="submit" disabled={isAddTransferSharePending}>
              {t('submit')}
            </StyledButton>
          ) : (
            <StyledButton
              type="primary"
              size="large"
              disabled={!shareholder_id || !number_of_shares}
              onClick={() => handlePaymentModalOpen()}
            >
              {t('payToTransfer')}
            </StyledButton>
          )}
        </Flex>
      </Form>
      <PaymentModal
        isOpen={isPaymentModalOpen}
        handleCancel={handlePaymentModalClose}
        forSaleShareID={issueShare.id}
        selectedInterestID={shareholder_id}
        numberOfShares={number_of_shares}
      />
    </CommonModal>
  );
};

export default TransferSharesModal;
