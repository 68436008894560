import { useTranslation } from 'react-i18next';
import { Row, Col, Card, Flex, Typography, Image, Grid } from 'antd';
import themeToken from '@lib/theme/tokens/index';
import MethodsBreakdown from './Valuation/MethodsBreakdown';
import { CompanyValuationDashboardData } from '../../types';
import DCFMethods from './Valuation/DCFMethods';
import RevenueAndCost from './Valuation/RevenueAndCost';
import lowBoundImage from '@/assets/low-bound.png';
import highBoundImage from '@/assets/high-bound.png';
import { StyledFlex } from './StyledFlex';
import { useSelectedCompanyStore } from '@/stores/selectedCompany';
import GeneratingReportBanner from './GeneratingReportBanner';
import { useGetCompany } from '@/features/company/api/getCompany';
import { formatCurrencyNumber } from '@/utils/formatCurrencyNumber';

const { Text } = Typography;
const { useBreakpoint } = Grid;

const devStage: { [key: string]: string } = {
  type1: 'Idea stage',
  type2: 'Development stage',
  type3: 'Startup stage',
  type4: 'Expansion stage',
  type5: 'Growth stage',
  type6: 'Maturity stage',
};

const Valuation = ({ data }: { data: CompanyValuationDashboardData | undefined }) => {
  const { t } = useTranslation('valuation', {
    keyPrefix: 'dashboard',
  });
  const screens = useBreakpoint();
  const devStageValue = devStage[data?.company_summary_data.devstage.split('.').pop() ?? ''];
  const { selectedCompany } = useSelectedCompanyStore((state) => state);
  const { data: companyData } = useGetCompany({
    companyID: selectedCompany?.cid as number,
  });
  const currency = companyData?.company_data?.company_currency.code ?? '';

  return (
    <Flex vertical align="center" justify="space-between">
      <StyledFlex gap={16} style={{ width: '100%', paddingBottom: '16px' }}>
        <Col span={24} style={{ flex: 1 }}>
          <Card
            bodyStyle={{ padding: '0px', height: '100%' }}
            style={{
              height: '100%',
              width: '100%',
              borderRadius: '8px',
              padding: '16px',
              display: 'flex',
              flexDirection: 'column',
              margin: '0px',
            }}
          >
            <Flex vertical gap={8}>
              <Image
                preview={false}
                src={companyData?.company_data.logo}
                alt={`${companyData?.company_data?.name} logo`}
                width={60}
                height={60}
                placeholder
              />
              <Text
                style={{
                  color: themeToken.colorPrimary,
                  fontSize: themeToken.fontSizeHeading4,
                  fontWeight: themeToken.fontWeightLG,
                  marginBottom: '8px',
                }}
              >
                {data?.startup_name}
              </Text>
            </Flex>
            <Row
              gutter={16}
              style={{
                width: '100%',
                margin: '0px',
                gap: '4px',
                marginBottom: '8px',
              }}
            >
              <Text
                style={{
                  color: themeToken['branding-natural-8'],
                  fontWeight: themeToken.fontWeightSM,
                }}
              >
                {t('startedIn')}
              </Text>
              <Text
                style={{
                  color: themeToken['branding-natural-8'],
                  fontWeight: themeToken.fontWeightSM,
                  fontSize: themeToken.fontSizeHeading7,
                }}
              >
                :
              </Text>
              <Text
                style={{
                  color: themeToken.colorPrimary,
                  fontWeight: themeToken.fontWeightMD,
                }}
              >
                {data?.company_summary_data?.startedyear}
              </Text>
            </Row>
            <Row
              gutter={16}
              style={{
                width: '100%',
                margin: '0px',
                gap: '4px',
                marginBottom: '8px',
              }}
            >
              <Text
                style={{
                  color: themeToken['branding-natural-8'],
                  fontWeight: themeToken.fontWeightSM,
                }}
              >
                {t('industry')}
              </Text>
              <Text
                style={{
                  color: themeToken['branding-natural-8'],
                  fontWeight: themeToken.fontWeightSM,
                  fontSize: themeToken.fontSizeHeading7,
                }}
              >
                :
              </Text>
              <Text
                style={{
                  color: themeToken.colorPrimary,
                  fontWeight: themeToken.fontWeightMD,
                }}
              >
                {data?.industry_lang}
              </Text>
            </Row>
            <Row
              gutter={16}
              style={{
                width: '100%',
                margin: '0px',
                gap: '4px',
                marginBottom: '8px',
              }}
            >
              <Text
                style={{
                  color: themeToken['branding-natural-8'],
                  fontWeight: themeToken.fontWeightSM,
                }}
              >
                {t('activity')}
              </Text>
              <Text
                style={{
                  color: themeToken['branding-natural-8'],
                  fontWeight: themeToken.fontWeightSM,
                  fontSize: themeToken.fontSizeHeading7,
                }}
              >
                :
              </Text>
              <Text
                style={{
                  color: themeToken.colorPrimary,
                  fontWeight: themeToken.fontWeightMD,
                }}
              >
                {data?.activity_lang}
              </Text>
            </Row>
            <Row
              gutter={16}
              style={{
                width: '100%',
                margin: '0px',
                gap: '4px',
                marginBottom: '8px',
              }}
            >
              <Text
                style={{
                  color: themeToken['branding-natural-8'],
                  fontWeight: themeToken.fontWeightSM,
                }}
              >
                {t('employees')}
              </Text>
              <Text
                style={{
                  color: themeToken['branding-natural-8'],
                  fontWeight: themeToken.fontWeightSM,
                  fontSize: themeToken.fontSizeHeading7,
                }}
              >
                :
              </Text>
              <Text
                style={{
                  color: themeToken.colorPrimary,
                  fontWeight: themeToken.fontWeightMD,
                }}
              >
                {data?.company_summary_data?.employeesnumber}
              </Text>
            </Row>
            <Row
              gutter={16}
              style={{
                width: '100%',
                margin: '0px',
                gap: '4px',
                marginBottom: '8px',
              }}
            >
              <Text
                style={{
                  color: themeToken['branding-natural-8'],
                  fontWeight: themeToken.fontWeightSM,
                }}
              >
                {t('stage')}
              </Text>
              <Text
                style={{
                  color: themeToken['branding-natural-8'],
                  fontWeight: themeToken.fontWeightSM,
                  fontSize: themeToken.fontSizeHeading7,
                }}
              >
                :
              </Text>
              <Text
                style={{
                  color: themeToken.colorPrimary,
                  fontWeight: themeToken.fontWeightMD,
                }}
              >
                {devStageValue}
              </Text>
            </Row>
            <Row
              gutter={16}
              style={{
                width: '100%',
                margin: '0px',
                gap: '4px',
                marginBottom: '8px',
              }}
            >
              <Text
                style={{
                  color: themeToken['branding-natural-8'],
                  fontWeight: themeToken.fontWeightSM,
                }}
              >
                {t('lastRevenues')}
              </Text>
              <Text
                style={{
                  color: themeToken['branding-natural-8'],
                  fontWeight: themeToken.fontWeightSM,
                  fontSize: themeToken.fontSizeHeading7,
                }}
              >
                :
              </Text>
              <Text
                style={{
                  color: themeToken.colorPrimary,
                  fontWeight: themeToken.fontWeightMD,
                }}
              >
                {formatCurrencyNumber(data?.company_summary_data?.estimatedrevenues_y1 as number, currency)}
              </Text>
            </Row>
          </Card>
        </Col>
        <Col span={24} style={{ flex: 1 }}>
          <Card
            bodyStyle={{ padding: '0px', height: '100%', display: 'flex', flexDirection: 'column' }}
            style={{
              width: '100%',
              height: '100%',
              borderRadius: '8px',
              padding: '16px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Text
              style={{ color: themeToken.colorPrimary, fontWeight: themeToken.fontWeightLG, marginBottom: '17.5px' }}
            >
              {t('preMoneyValuation')}
            </Text>
            <StyledFlex
              align="center"
              justify="space-between"
              flex={1}
              gap={screens.xl ? 16 : 8}
              style={{
                marginInline: screens.xl ? '32px' : 0,
              }}
            >
              <div
                style={{
                  width: '226px',
                  borderWidth: '7px',
                  position: 'relative',
                  background: `linear-gradient(to bottom, ${themeToken.colorPrimary}, #6040D4 )`,
                  borderRadius: '50%',
                  height: '226px',
                  padding: '7px',
                }}
              >
                <Flex
                  align="center"
                  justify="center"
                  vertical
                  style={{
                    borderRadius: '50%',
                    maxWidth: '212px',
                    background: 'white',
                    height: '100%',
                  }}
                >
                  <Flex align="baseline" wrap="wrap" gap={4}>
                    <Text
                      style={{
                        color: themeToken.colorPrimary,
                        fontWeight: themeToken.fontWeightLG,
                        fontSize: themeToken.fontSizeHeading3,
                      }}
                    >
                      {formatCurrencyNumber(data?.pre_money_full as number, currency)}
                    </Text>
                  </Flex>

                  <Text
                    style={{
                      fontWeight: themeToken.fontWeightNormal,
                      color: themeToken.colorPrimary,
                    }}
                  >
                    {t('preMoneyValuation')}
                  </Text>
                </Flex>
              </div>
              <Flex
                align="center"
                justify="space-between"
                style={{
                  width: '100%',
                  maxWidth: screens.md ? '121px' : '500px',
                  textAlign: 'center',
                }}
                vertical={screens.md}
                gap={12}
              >
                <Flex align="center" vertical>
                  <Image src={lowBoundImage} preview={false} alt={'low-bound-image'} style={{ marginBottom: '8px' }} />
                  <Text
                    style={{
                      fontSize: themeToken.fontSizeHeading7,
                      fontWeight: themeToken.fontWeightLG,
                      color: themeToken.colorPrimary,
                    }}
                  >
                    {t('lowBound')}
                  </Text>
                  <Text
                    style={{
                      color: themeToken['branding-natural-6'],
                    }}
                  >
                    {formatCurrencyNumber(data?.val_bounds?.low as number, currency)}
                  </Text>
                </Flex>
                <Flex align="center" vertical>
                  <Image
                    src={highBoundImage}
                    preview={false}
                    alt={'high-bound-image'}
                    style={{ marginBottom: '8px' }}
                  />
                  <Text
                    style={{
                      fontSize: themeToken.fontSizeHeading7,
                      fontWeight: themeToken.fontWeightLG,
                      color: themeToken.colorPrimary,
                    }}
                  >
                    {t('highBound')}
                  </Text>
                  <Text
                    style={{
                      color: themeToken['branding-natural-6'],
                    }}
                  >
                    {formatCurrencyNumber(data?.val_bounds?.high as number, currency)}
                  </Text>
                </Flex>
              </Flex>
            </StyledFlex>
          </Card>
        </Col>
      </StyledFlex>
      <StyledFlex vertical gap={16} style={{ width: '100%' }}>
        <Col span={48} style={{ flex: 1 }}>
          <MethodsBreakdown data={data?.methods_module} />
        </Col>
        <Col span={48} style={{ flex: 1 }}>
          <DCFMethods data={data?.dcf_methods_chart} dataDetails={data?.dcf_methods_rightcol} />
        </Col>

        <Col span={48} style={{ flex: 1 }}>
          <GeneratingReportBanner />
        </Col>
      </StyledFlex>
      <Row gutter={16} style={{ width: '100%', marginBottom: '16px' }}>
        <Col span={24} style={{ flex: 1 }}>
          <RevenueAndCost data={data?.revcost_module} />
        </Col>
      </Row>
    </Flex>
  );
};

export default Valuation;
