import { useEffect, useMemo, useState } from 'react';
import { Limitation } from '@/types/limitations';
import { Flex } from 'antd';
import { Outlet, useLocation } from 'react-router-dom';
import { useSelectedCompanyStore } from '@/stores/selectedCompany';
import { useCheckFeatureLimits } from '@/features/misc/checkFeatureLimit';
import PinnedNotification from './Shared/PinnedNotification';
import dayjs from 'dayjs';

type Props = {
  limitKeys: Limitation[];
  feature: string;
  showHud?: boolean;
};

export type LimitsCheckerContext = {
  recheckLimits: () => void;
  disableRecordsAddition: boolean;
  limitations: {
    key: string;
    limit: number;
    usage: number;
    expiration_date: string;
    percentage: number;
  }[];
};

const LimitsChecker = ({ limitKeys, feature, showHud = true }: Props) => {
  const location = useLocation();
  const { selectedCompany, isPremiumPlan, expiresAt } = useSelectedCompanyStore((state) => state);
  const { mutate, data: limitsResponse } = useCheckFeatureLimits({});
  const [packageExpiryDate, setPackageExpiryDate] = useState('');
  const keysMapper: Record<string, string> = useMemo(
    () => ({
      'limits.company.dataroom.folders': 'folder',
      'limits.company.dataroom.files': 'file',
      'limits.company.dataroom.shares': 'share',
    }),
    [],
  );

  const checkLimits = () => {
    mutate({
      companyID: selectedCompany?.cid as number,
      limitKeys,
    });
  };

  const showLimitHud = useMemo(() => {
    if (limitsResponse) {
      const { limitations } = limitsResponse;
      const [firstLimit] = limitations;
      const modifiedLimitations = limitations.map(({ limit, usage, expiration_date, key }) => ({
        key: keysMapper[key] || key,
        limit,
        usage,
        expiration_date,
        percentage: limit === 0 ? 0 : (usage / limit) * 100,
      }));

      if (feature === 'valuation') setPackageExpiryDate(() => firstLimit.expiration_date);

      return { show: modifiedLimitations.some(({ percentage }) => percentage >= 80), modifiedLimitations };
    }
    return { show: false, modifiedLimitations: [] };
  }, [feature, keysMapper, limitsResponse]);

  const expiryInterval = useMemo(() => {
    if (expiresAt) {
      const currentDate = dayjs(new Date());
      const planExpiry = dayjs(new Date(expiresAt));
      const packageExpiry = dayjs(new Date(packageExpiryDate));

      const planDiff = Math.floor(planExpiry.diff(currentDate, 'day', true));
      const packageDiff = Math.floor(packageExpiry.diff(currentDate, 'day', true));
      return feature === 'valuation' ? packageDiff : planDiff;
    }
    return null;
  }, [feature, expiresAt, packageExpiryDate]);

  const notificationHud = useMemo(() => {
    if (!showHud) return null;

    // Valuation => expiry date <= 14 -> otherwise -> expiry date <= 60
    if (feature === 'valuation' && expiryInterval !== null && expiryInterval <= 14)
      return (
        <PinnedNotification
          feature={feature}
          type="info"
          expiryInterval={expiryInterval < 0 ? 0 : expiryInterval}
          expiryDate={packageExpiryDate}
        />
      );
    else if (expiryInterval !== null && expiryInterval <= 60)
      return (
        <PinnedNotification
          feature={feature}
          type="info"
          expiryInterval={expiryInterval < 0 ? 0 : expiryInterval}
          expiryDate={feature === 'valuation' ? packageExpiryDate : expiresAt}
        />
      );
    else if (!isPremiumPlan && showLimitHud?.modifiedLimitations?.some(({ usage }) => usage > 0))
      return <PinnedNotification type="error" limits={showLimitHud.modifiedLimitations} feature={feature} />;
    else if (showLimitHud.show && showLimitHud?.modifiedLimitations?.every(({ limit }) => limit !== 0))
      return <PinnedNotification type="error" limits={showLimitHud.modifiedLimitations} feature={feature} />;
  }, [
    showHud,
    expiresAt,
    expiryInterval,
    feature,
    isPremiumPlan,
    packageExpiryDate,
    showLimitHud.modifiedLimitations,
    showLimitHud.show,
  ]);

  const containerMargin = useMemo(() => {
    if (!showHud) return 0;

    if (expiryInterval && expiryInterval <= 60) return '56px';
    else if (
      showLimitHud.show ||
      (!isPremiumPlan && showLimitHud?.modifiedLimitations?.some(({ usage }) => usage > 0))
    ) {
      if (showLimitHud?.modifiedLimitations.length === 1) return '70px';
      else return '165px';
    }
    return 0;
  }, [showHud, expiryInterval, isPremiumPlan, showLimitHud?.modifiedLimitations, showLimitHud.show]);

  useEffect(() => {
    checkLimits();
  }, [location.pathname]);

  return (
    <Flex vertical>
      {notificationHud}

      <Flex
        vertical
        style={{
          marginTop: containerMargin,
        }}
      >
        <Outlet
          context={
            {
              recheckLimits: checkLimits,
              disableRecordsAddition:
                showLimitHud?.modifiedLimitations?.every(({ percentage }) => percentage >= 100) ||
                (expiryInterval && expiryInterval <= 0) ||
                false,
              limitations: showLimitHud?.modifiedLimitations,
            } satisfies LimitsCheckerContext
          }
        />
      </Flex>
    </Flex>
  );
};

export default LimitsChecker;
