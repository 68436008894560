import { useMemo } from 'react';
import { Flex, Typography, Skeleton } from 'antd';

import themeToken from '@lib/theme/tokens/index';

const { Text } = Typography;

type Props = { label: string; value?: string | number | JSX.Element; isLoading: boolean };

const DetailsItem = ({ label, value, isLoading }: Props) => {
  const valueContent = useMemo(() => {
    if (isLoading)
      return (
        <Skeleton.Button
          active
          size="small"
          shape="round"
          block
          style={{ height: 16, marginTop: themeToken.marginXXS }}
        />
      );

    return (
      <Text
        style={{
          fontSize: themeToken.fontSizeLG,
          color: themeToken['branding-primary-6'],
          fontWeight: 600,
        }}
      >
        {value ?? '-'}
      </Text>
    );
  }, [isLoading, value]);

  return (
    <Flex
      vertical
      style={{
        flexGrow: 1,
      }}
    >
      <Text
        style={{
          color: themeToken['branding-natural-6'],
          fontSize: themeToken.fontSize,
          marginBottom: themeToken.marginXXS,
        }}
      >
        {label}
      </Text>

      {valueContent}
    </Flex>
  );
};

export default DetailsItem;
