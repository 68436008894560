import { useTranslation } from 'react-i18next';
import { Flex, Grid, Typography } from 'antd';
import dayjs from 'dayjs';
import { Area, AreaChart, ResponsiveContainer, Tooltip, XAxis, TooltipProps } from 'recharts';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';

import { useSelectedCompanyStore } from '@/stores/selectedCompany';
import { ChartSkeleton } from '@/components/Icons/ChartSkeleton';
import { CellSkeleton } from '@/components/Shared/CellSkeleton';
import { formatNumber } from '@/utils/formatNumber';
import ChartEmptyState from '@/components/Icons/ChartEmptyState';
import { formatCurrencyNumber } from '@/utils/formatCurrencyNumber';
import themeToken from '@lib/theme/tokens/index';

import { useGetValuationHistory } from '../api/getValuationHistory';
import { useGetCompany } from '../api/getCompany';
import type { GraphData } from '../types';

const { Title, Text } = Typography;
const { useBreakpoint } = Grid;

const ValuationHistory = () => {
  const screens = useBreakpoint();
  const { t, i18n } = useTranslation('captable', {
    keyPrefix: 'overview',
  });
  dayjs.locale(i18n.language);
  const { selectedCompany } = useSelectedCompanyStore((state) => state);
  const { data: selectedCompanyData } = useGetCompany({
    companyID: selectedCompany?.cid as number,
  });
  const { data: valuationHistoryData, isLoading: isValuationLoading } = useGetValuationHistory({
    companyID: selectedCompany?.cid as number,
  });

  const chartData = valuationHistoryData?.graph?.map((el) => ({
    ...el,
    share_date: dayjs(el.share_date).format('DD MMM YYYY'),
    label: dayjs(el.share_date).format('MMM YYYY'),
  }));

  const currencyCode = selectedCompanyData?.company_data?.company_currency.code ?? '';

  const CustomTooltip = ({ active, payload }: TooltipProps<ValueType, NameType>) => {
    if (active && payload && payload.length) {
      return (
        <Flex
          className="custom-tooltip"
          gap={8}
          vertical
          style={{
            padding: '8px 16px',
            borderRadius: '8px 8px 8px 0px',
            background: themeToken.suggestedNameColor,
          }}
        >
          <Flex gap={16} justify="space-between">
            <Flex vertical>
              <Text
                style={{
                  color: themeToken.white,
                  fontSize: 12,
                }}
              >
                {t('className')}
              </Text>
              <Text
                style={{
                  color: themeToken.white,
                  fontSize: 14,
                }}
                strong
              >
                {(payload[0].payload as GraphData).share_name}
              </Text>
            </Flex>
            <Flex vertical>
              <Text
                style={{
                  color: themeToken.white,
                  fontSize: 12,
                }}
              >
                {t('totalValuation')}
              </Text>
              <Text
                style={{
                  color: themeToken.white,
                  fontSize: 14,
                }}
                strong
              >
                {formatCurrencyNumber(payload[0].value as number, currencyCode)}
              </Text>
            </Flex>
          </Flex>
          <Flex gap={16} justify="space-between">
            <Flex vertical>
              <Text
                style={{
                  color: themeToken.white,
                  fontSize: 12,
                }}
              >
                {t('sharePrice')}
              </Text>
              <Text
                style={{
                  color: themeToken.white,
                  fontSize: 14,
                }}
                strong
              >
                {formatCurrencyNumber((payload[0].payload as GraphData).share_price, currencyCode)}
              </Text>
            </Flex>
            <Flex vertical>
              <Text
                style={{
                  color: themeToken.white,
                  fontSize: 12,
                }}
              >
                {t('numberOfShares')}
              </Text>
              <Text
                style={{
                  color: themeToken.white,
                  fontSize: 14,
                }}
                strong
              >
                {`${formatNumber((payload[0].payload as GraphData).shares)} ${t('share')}`}
              </Text>
            </Flex>
          </Flex>
          <Text
            style={{
              color: themeToken.white,
              fontSize: 12,
            }}
          >
            {(payload[0].payload as GraphData).share_date}
          </Text>
        </Flex>
      );
    }

    return null;
  };

  return (
    <Flex vertical gap={12} justify="space-between">
      <Title
        level={5}
        style={{
          color: themeToken.colorPrimary,
        }}
      >
        {t('valuationHistory')}
      </Title>

      <Flex gap={12} vertical={!screens.sm}>
        {isValuationLoading ? (
          <div
            style={{
              width: !screens.sm ? '100%' : '75%',
            }}
          >
            <ChartSkeleton />
          </div>
        ) : !chartData ? (
          <ResponsiveContainer width={!screens.sm ? '100%' : '75%'} height={398}>
            <Flex vertical justify="center" align="center" style={{ height: '100%' }}>
              <ChartEmptyState />
              <Text
                style={{
                  marginTop: themeToken.marginSM,
                  color: themeToken['branding-primary-4'],
                  fontSize: 14,
                }}
              >
                {t('noChartData')}
              </Text>
            </Flex>
          </ResponsiveContainer>
        ) : (
          <ResponsiveContainer width={!screens.sm ? '100%' : '75%'} height={398}>
            <AreaChart data={chartData} width={573} height={398}>
              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0%" stopColor={themeToken.colorPrimary} stopOpacity={0.5} />
                  <stop offset="100%" stopColor={themeToken.white} stopOpacity={0.25} />
                </linearGradient>
              </defs>
              <XAxis dataKey="label" interval="preserveStartEnd" />
              <Tooltip content={<CustomTooltip />} />
              <Area
                type="monotone"
                dataKey="total_valuation"
                stroke={themeToken.colorPrimary}
                strokeWidth={4}
                fill="url(#colorUv)"
              />
            </AreaChart>
          </ResponsiveContainer>
        )}
        <Flex
          gap={48}
          vertical={screens.sm}
          wrap="wrap"
          style={{
            padding: '50px 16px ',
          }}
          justify="center"
        >
          <Flex gap={2} vertical>
            <Text
              style={{
                color: themeToken.colorPrimary,
                fontSize: 20,
              }}
              strong
            >
              {isValuationLoading ? (
                <CellSkeleton />
              ) : !valuationHistoryData?.latest_share_name ? (
                'N/A'
              ) : (
                valuationHistoryData?.latest_share_name
              )}
            </Text>
            <Text
              style={{
                color: themeToken.disabledColor,
                fontSize: 16,
              }}
            >
              {dayjs(valuationHistoryData?.latest_share_date).format('DD MMM YYYY')}
            </Text>
          </Flex>
          <Flex gap={2} vertical>
            <Text
              style={{
                color: themeToken.colorPrimary,
                fontSize: 20,
              }}
              strong
            >
              {isValuationLoading ? (
                <CellSkeleton />
              ) : !valuationHistoryData?.latest_valuation && valuationHistoryData?.latest_valuation !== 0 ? (
                'N/A'
              ) : (
                formatCurrencyNumber(valuationHistoryData?.latest_valuation, currencyCode)
              )}
            </Text>
            <Text
              style={{
                color: themeToken.disabledColor,
                fontSize: 16,
              }}
            >
              {t('latestValuation')}
            </Text>
          </Flex>
          <Flex gap={2} vertical>
            <Text
              style={{
                color: themeToken.colorPrimary,
                fontSize: 20,
              }}
              strong
            >
              {isValuationLoading ? (
                <CellSkeleton />
              ) : !valuationHistoryData?.total_capital_raised && valuationHistoryData?.total_capital_raised !== 0 ? (
                'N/A'
              ) : (
                formatCurrencyNumber(valuationHistoryData?.total_capital_raised, currencyCode)
              )}
            </Text>
            <Text
              style={{
                color: themeToken.disabledColor,
                fontSize: 16,
              }}
            >
              {t('totalCapitalRaised')}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ValuationHistory;
