import { Flex, Skeleton, Typography } from 'antd';

import themeToken from '@lib/theme/tokens/index';

const { Text } = Typography;

type Props = {
  title: string;
  value: string | JSX.Element;
  isLoading?: boolean;
};

const ItemInfo = ({ title, value, isLoading }: Props) => {
  return (
    <Flex gap={2} vertical>
      <Text
        style={{
          color: themeToken['branding-natural-6'],
          fontSize: themeToken.fontSizeSM,
          fontWeight: 600,
        }}
      >
        {title}
      </Text>
      {isLoading ? (
        <Skeleton paragraph={false} active />
      ) : (
        <Text
          style={{
            color: themeToken.colorPrimary,
            fontSize: themeToken.fontSizeXL,
            fontWeight: 600,
          }}
        >
          {value}
        </Text>
      )}
    </Flex>
  );
};

export default ItemInfo;
