import { Flex, Progress, Typography } from 'antd';
import themeToken from '@lib/theme/tokens/index';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

export type UserPlanProgressItemProps = {
  title?: string | null;
  used?: number;
  limit?: number;
  progressInfo?: React.ReactNode;
};

const AvailableSpaceIsUsed = styled.div`
  background-color: ${themeToken['branding-natural-3']};
  margin-top: 8px;
  border-radius: 8px;
  padding: 12px;
`;

const UserPlanProgressItem = ({ title, used, limit, progressInfo }: UserPlanProgressItemProps) => {
  const { t } = useTranslation('plans');

  const isProgressComplete = () => {
    if (!used || !limit) {
      return <Progress percent={0} strokeColor={themeToken['branding-secondary-6']} showInfo={false} />;
    }
    if (used >= limit) {
      return <Progress strokeColor={themeToken.colorWarningText} percent={(used / limit) * 100} showInfo={false} />;
    } else {
      return (
        <Progress strokeColor={themeToken['branding-secondary-6']} percent={(used / limit) * 100} showInfo={false} />
      );
    }
  };

  const isUsedAllAvailableUsage = () => {
    if (!limit || !used) {
      return null;
    }

    if (used >= limit) {
      return (
        <AvailableSpaceIsUsed>
          <Text
            style={{
              color: themeToken['branding-natural-8'],
              fontSize: themeToken.fontSizeSM,
            }}
          >
            {t('yourAvailableIsUsed')} {title}{' '}
            <Link style={{ textDecoration: 'underline' }} to={'/go-premium/plans'}>
              {t('goToPackagesNow')}
            </Link>
            .
          </Text>
        </AvailableSpaceIsUsed>
      );
    }
  };

  const isUnlimited = () => {
    if (limit === 0) {
      return (
        <Flex justify="space-between">
          <Text
            style={{
              fontWeight: 600,
            }}
          >
            {title}
          </Text>
          <Text>Unlimited</Text>
        </Flex>
      );
    } else {
      return (
        <Flex vertical>
          {title && (
            <Text
              style={{
                fontWeight: 600,
              }}
            >
              {title}
            </Text>
          )}
          {isProgressComplete()}
          {progressInfo}
          {isUsedAllAvailableUsage()}
        </Flex>
      );
    }
  };

  return isUnlimited();
};

export default UserPlanProgressItem;
