import { Button, Card, Divider, Flex, Tag, Typography } from 'antd';
import themeToken from '@lib/theme/tokens/index';
import { useTranslation } from 'react-i18next';
import { StarIcon } from '@/assets/Icon/star';
import UserPlanHeaderCards from '../components/UserPlanHeaderCards';
import StorageIcon from '@/components/Icons/StorageIcon';
import { UserIcon } from '@/components/Icons/UserIcon';
import UserPlanFeatureUsageContainer from '../components/UserPlanFeatureUsageContainer';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useGetCompany } from '@/features/company/api/getCompany';
import { useSelectedCompanyStore } from '@/stores/selectedCompany';
import SARIcon from '@/components/Icons/SARIcon';
import { useNavigate } from 'react-router-dom';
import { useCheckFeatureLimits } from '@/features/misc/checkFeatureLimit';
import { FolderCloudIcon } from '@/components/Icons/FolderCloudIcon';
import GraphIcon from '@/components/Icons/GraphIcon';
import ShieldIcon from '@/components/Icons/ShieldIcon';
import { useGetUsedStorage } from '../api/getUsedStorage';
import CommonTable from '@/components/Shared/CommonTable';
import { useGetPaymentHistory } from '../api/getPaymentHistory';
import { paymentHistoryColumns } from '../utils/PaymentHistoryColumns';

const { Text } = Typography;

const UserPlan = () => {
  const { t } = useTranslation('plans');

  const { selectedCompany } = useSelectedCompanyStore((state) => state);
  const { data: companyData } = useGetCompany({
    companyID: selectedCompany?.cid as number,
  });
  const { data: usedStorage } = useGetUsedStorage({
    companyID: selectedCompany?.cid as number,
  });
  const { mutate, data: limitsResponse } = useCheckFeatureLimits({});

  const [paginationParams, setPaginationParams] = useState({
    pageSize: 10,
    total: 0,
    current: 1,
  });
  const { data: paymentHistory, isPending: isPaymentHistoryLoading } = useGetPaymentHistory({
    companyID: selectedCompany?.cid as number,
    queryParams: {
      page: paginationParams.current,
      limit: paginationParams.pageSize,
    },
  });

  const paymentHistoryColumnsData = paymentHistoryColumns({
    isLoading: isPaymentHistoryLoading,
    t: t,
  });

  const navigate = useNavigate();

  useEffect(() => {
    mutate({
      companyID: selectedCompany?.cid as number,
      limitKeys: [
        'limits.company.dataroom.folders',
        'limits.company.dataroom.files',
        'limits.company.dataroom.shares',
        'limits.company.users',
        'limits.company.equities',
        'limits.company.options',
        'limits.company.convertible-instruments',
        'limits.company.vesting_plan',
        'company.valuation',
      ],
    });
  }, [mutate]);

  useEffect(() => {
    if (limitsResponse) {
      console.log(limitsResponse);
    }
  }, [limitsResponse]);

  const getAvailableDataroomLimit = (key: string) => {
    const keyLimit = limitsResponse?.limitations.find((item) => item.key === key)?.limit || 0;
    const keyUsage = limitsResponse?.limitations.find((item) => item.key === key)?.usage || 0;

    return keyLimit - keyUsage;
  };

  const planIcon = () => {
    switch (companyData?.current_plan?.name) {
      case 'Enterprise':
        return <ShieldIcon width={50} height={50} />;
      case 'Scale':
        return <ShieldIcon width={50} height={50} />;
      case 'Startup':
        return <StarIcon height={50} width={50} rotation={-20} fillColor="white" />;
      default:
        return (
          <div
            style={{
              width: '50px',
              height: '50px',
            }}
          ></div>
        );
    }
  };

  const isExpired = () => {
    if (dayjs().isAfter(dayjs(companyData?.expired_at))) {
      return <>{t('subscribtionExpiredAtDateTitle')}</>;
    } else {
      return <>{t('subscribtionExpireDateTitle')}</>;
    }
  };

  return (
    <>
      <Text
        style={{
          color: themeToken['branding-primary-6'],
          fontWeight: 600,
          marginBottom: themeToken.marginSM,
          fontSize: themeToken.fontSizeHeading5,
        }}
      >
        {t('profileManagement')}
      </Text>
      <Flex vertical gap={8}>
        <Card>
          <Flex justify="space-between" align="center">
            <Text
              style={{
                color: themeToken['branding-primary-6'],
                fontWeight: 600,
                fontSize: themeToken.fontSizeHeading5,
              }}
            >
              {t('yourSubscriptionPlan')}
            </Text>
            <Flex gap={8}>
              <Button
                type="primary"
                onClick={() => {
                  navigate('/go-premium/plans');
                }}
              >
                {t('upgradePlanButton')}
              </Button>
            </Flex>
          </Flex>
          <Divider />
          <Flex gap={8} align="center" justify="space-between">
            <Flex gap={8} align="center">
              <div
                style={{
                  backgroundColor: themeToken.primary_700,
                  borderRadius: '10%',
                  padding: 10,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {planIcon()}
              </div>
              <Flex vertical>
                <Flex gap={8} style={{ marginBottom: themeToken.marginSM }} align="center">
                  <Text
                    style={{
                      color: themeToken['branding-primary-6'],
                      fontWeight: 600,

                      fontSize: themeToken.fontSizeHeading5,
                    }}
                  >
                    {companyData?.current_plan?.name}
                  </Text>
                  <Tag color={limitsResponse?.is_subscription_expired ? 'red' : 'green'}>
                    {limitsResponse?.is_subscription_expired ? t('expired') : t('active')}
                  </Tag>
                </Flex>
                <Text
                  style={{
                    color: themeToken['branding-primary-4'],
                    fontSize: themeToken.fontSizeHeading6,
                    fontWeight: 700,
                  }}
                >
                  {isExpired()} {dayjs(companyData?.expired_at).format('DD/MM/YYYY')}
                  {dayjs(companyData?.expired_at).isAfter(dayjs())
                    ? ' (' + dayjs(companyData?.expired_at).diff(dayjs(), 'days')
                    : ' (0'}{' '}
                  {' ' + t('daysLeft') + ')'}
                </Text>
              </Flex>
            </Flex>
            <Flex align="center" gap={8}>
              <Text
                style={{
                  fontWeight: 600,
                  fontSize: themeToken.fontSizeHeading2,
                }}
              >
                <SARIcon height={20} width={20} /> {companyData?.current_plan?.price}
              </Text>
              <Text
                style={{
                  color: themeToken['branding-natural-7'],
                  fontSize: themeToken.fontSizeSM,
                }}
              >
                {t('payedYearly')}
              </Text>
            </Flex>
          </Flex>
          <Flex gap={8} style={{ marginTop: themeToken.marginSM }}>
            <UserPlanHeaderCards
              title={t('storage')}
              icon={<StorageIcon width={20} height={20} />}
              used={usedStorage?.totalUsedStorage}
              limit={10024}
              progressInfo={
                <Flex justify="space-between">
                  <Text style={{ color: themeToken['branding-natural-6'] }}>
                    {usedStorage?.totalUsedStorage.toFixed(3)} MB
                  </Text>{' '}
                  <Text style={{ color: themeToken['branding-natural-6'] }}>10 GB</Text>
                </Flex>
              }
            />
            <UserPlanHeaderCards
              title={t('Shareholders')}
              icon={<UserIcon />}
              used={limitsResponse?.limitations.find((item) => item.key === 'limits.company.users')?.usage}
              limit={limitsResponse?.limitations.find((item) => item.key === 'limits.company.users')?.limit}
              progressInfo={
                <Flex justify="space-between">
                  <Text style={{ color: themeToken['branding-natural-6'] }}>
                    {limitsResponse?.limitations.find((item) => item.key === 'limits.company.users')?.usage}{' '}
                    {t('usersAdded')}
                  </Text>{' '}
                  <Text style={{ color: themeToken['branding-natural-6'] }}>
                    {limitsResponse?.limitations.find((item) => item.key === 'limits.company.users')?.limit}{' '}
                    {t('users')}
                  </Text>
                </Flex>
              }
            />
          </Flex>
        </Card>
        <Card>
          <Flex justify="space-between" align="center">
            <Text
              style={{
                color: themeToken['branding-primary-6'],
                fontWeight: 600,
                fontSize: themeToken.fontSizeHeading5,
              }}
            >
              {t('planFeaturesUsage')}
            </Text>
            {/* <Flex gap={8}>
                <Button></Button>
                <Button type="primary">Upgrade your plan</Button>
            </Flex> */}
          </Flex>
          <Divider />
          <Flex gap={8}>
            <UserPlanFeatureUsageContainer
              title={t('Captable')}
              icon={<GraphIcon width={20} height={20} />}
              progressItems={[
                {
                  title: t('Equities'),
                  used: limitsResponse?.limitations.find((item) => item.key === 'limits.company.equities')?.usage,
                  limit: limitsResponse?.limitations.find((item) => item.key === 'limits.company.equities')?.limit,
                  progressInfo: (
                    <Text style={{ color: themeToken['branding-natural-6'] }}>
                      {limitsResponse?.limitations.find((item) => item.key === 'limits.company.equities')?.usage}{' '}
                      {t('usedOf')}{' '}
                      {limitsResponse?.limitations.find((item) => item.key === 'limits.company.equities')?.limit}{' '}
                      {t('Equities')}
                    </Text>
                  ),
                },
                {
                  title: t('Option Pools'),
                  used: limitsResponse?.limitations.find((item) => item.key === 'limits.company.options')?.usage,
                  limit: limitsResponse?.limitations.find((item) => item.key === 'limits.company.options')?.limit,
                  progressInfo: (
                    <Text style={{ color: themeToken['branding-natural-6'] }}>
                      {limitsResponse?.limitations.find((item) => item.key === 'limits.company.options')?.usage}{' '}
                      {t('usedOf')}{' '}
                      {limitsResponse?.limitations.find((item) => item.key === 'limits.company.options')?.limit}{' '}
                      {t('Option Pools')}
                    </Text>
                  ),
                },
                {
                  title: t('Convertible Notes'),
                  used: limitsResponse?.limitations.find(
                    (item) => item.key === 'limits.company.convertible-instruments',
                  )?.usage,
                  limit: limitsResponse?.limitations.find(
                    (item) => item.key === 'limits.company.convertible-instruments',
                  )?.limit,
                  progressInfo: (
                    <Text style={{ color: themeToken['branding-natural-6'] }}>
                      {
                        limitsResponse?.limitations.find(
                          (item) => item.key === 'limits.company.convertible-instruments',
                        )?.usage
                      }{' '}
                      {t('usedOf')}{' '}
                      {
                        limitsResponse?.limitations.find(
                          (item) => item.key === 'limits.company.convertible-instruments',
                        )?.limit
                      }{' '}
                      {t('Convertible Notes')}
                    </Text>
                  ),
                },
                {
                  title: t('Vesting Plans'),
                  used: limitsResponse?.limitations.find((item) => item.key === 'limits.company.vesting_plan')?.usage,
                  limit: limitsResponse?.limitations.find((item) => item.key === 'limits.company.vesting_plan')?.limit,
                  progressInfo: (
                    <Text style={{ color: themeToken['branding-natural-6'] }}>
                      {limitsResponse?.limitations.find((item) => item.key === 'limits.company.vesting_plan')?.usage}{' '}
                      {t('usedOf')}{' '}
                      {limitsResponse?.limitations.find((item) => item.key === 'limits.company.vesting_plan')?.limit}{' '}
                      {t('Vesting Plans')}
                    </Text>
                  ),
                },
              ]}
            />
            <UserPlanFeatureUsageContainer
              title={t('Dataroom')}
              icon={<FolderCloudIcon width={20} height={20} />}
              progressItems={[
                {
                  title: t('folders'),
                  used: limitsResponse?.limitations.find((item) => item.key === 'limits.company.dataroom.folders')
                    ?.usage,
                  limit: limitsResponse?.limitations.find((item) => item.key === 'limits.company.dataroom.folders')
                    ?.limit,
                  progressInfo: (
                    <Text style={{ color: themeToken['branding-natural-6'] }}>
                      {t('only')} {getAvailableDataroomLimit('limits.company.dataroom.folders')} {t('folders')}{' '}
                      {t('areAvailable')}.
                    </Text>
                  ),
                },
                {
                  title: t('files'),
                  used: limitsResponse?.limitations.find((item) => item.key === 'limits.company.dataroom.files')?.usage,
                  limit: limitsResponse?.limitations.find((item) => item.key === 'limits.company.dataroom.files')
                    ?.limit,
                  progressInfo: (
                    <Text style={{ color: themeToken['branding-natural-6'] }}>
                      {t('only')} {getAvailableDataroomLimit('limits.company.dataroom.files')} {t('files')}{' '}
                      {t('areAvailable')}
                    </Text>
                  ),
                },
                {
                  title: t('fileShare'),
                  used: limitsResponse?.limitations.find((item) => item.key === 'limits.company.dataroom.shares')
                    ?.usage,
                  limit: limitsResponse?.limitations.find((item) => item.key === 'limits.company.dataroom.shares')
                    ?.limit,
                  progressInfo: (
                    <Text style={{ color: themeToken['branding-natural-6'] }}>
                      {t('only')} {getAvailableDataroomLimit('limits.company.dataroom.shares')} {t('fileShare')}{' '}
                      {t('areAvailable')}
                    </Text>
                  ),
                },
              ]}
            />
            <UserPlanFeatureUsageContainer
              title={t('Valuation')}
              icon={<FolderCloudIcon width={20} height={20} />}
              progressItems={[
                {
                  title: t('Valuation'),
                  used: limitsResponse?.limitations.find((item) => item.key === 'company.valuation')?.usage,
                  limit: limitsResponse?.limitations.find((item) => item.key === 'company.valuation')?.limit,
                  progressInfo: (
                    <Flex vertical gap={8}>
                      <Text style={{ color: themeToken['branding-natural-6'] }}>
                        {t('youCanCreate')} {getAvailableDataroomLimit('company.valuation')} {t('Valuation')}
                      </Text>
                      <Text style={{ color: themeToken['branding-natural-6'] }}>
                        {t('expireAt')}{' '}
                        {dayjs(
                          limitsResponse?.limitations.find((item) => item.key === 'company.valuation')?.expiration_date,
                        ).format('DD/MM/YYYY')}
                      </Text>
                    </Flex>
                  ),
                },
              ]}
            />
          </Flex>
        </Card>
        <Card>
          <Flex vertical gap={8}>
            <Text
              style={{
                color: themeToken['branding-primary-6'],
                fontWeight: 600,
                fontSize: themeToken.fontSizeHeading5,
              }}
            >
              {t('paymentHistory')}
            </Text>
            <Divider />
            <CommonTable
              columns={paymentHistoryColumnsData}
              paginationParams={paginationParams}
              setPaginationParams={setPaginationParams}
              dataSource={paymentHistory}
            />
          </Flex>
        </Card>
      </Flex>
    </>
  );
};

export default UserPlan;
